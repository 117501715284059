import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { AuthenticationService } from 'app/auth/authentication.service';

import { environment } from 'app/environment/environment';

@Injectable()
export class UserIntegTermService
{
    apiUrl = environment.apiUrl + "/user/integ/term";
    
    constructor(
        private _httpClient: HttpClient,
        private _authActivity: AuthenticationService
    )
    {}

    find(): Promise<any> {
        return new Promise((resolve) => {
            this._httpClient.get(this.apiUrl + '/find').subscribe((response) => {
                resolve(response);
            });
        });
    }

    accept(integTerm): Promise<any> {
        let request = {
            integTerm: integTerm
        };

        return new Promise((resolve) => {
            this._httpClient.put(this.apiUrl + '/accept', {...request}).subscribe((response) => {
                resolve(response);
            });
        });
    }
}