import { FuseUtils } from '@fuse/utils';

import { User } from 'app/main/configurations/user/user.model';
import { Post } from 'app/main/model/post.model';

export class PostComment
{
    id: string;
    message: string;
    post: Post;
    postComment: PostComment
    createdBy: User;
    creationDate: Date;

    /**
     * Constructor
     *
     * @param postComment
     */
    constructor(postComment?)
    {
        postComment = postComment || {};
        this.id = postComment.id || '';
        this.message = postComment.message || '';
        this.post = postComment.post || [];
        this.postComment = postComment.postComment || '';
        this.createdBy = postComment.createdBy || '';
        this.creationDate = postComment.creationDate || '';
    }
}