import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class MobileConfigService
{
    /**
     * Constructor
     *
     */
    constructor()
    {}

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Check if it's being accessed from mobiled device
     *
     */
    isMobile(): boolean {
        let ua = navigator.userAgent;

        if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(ua)) {
            return true;
        } else {
            return false;
        }
    }

    getDisplayedColumns(displayedColumns): string[] {
        let isMobile = this.isMobile;
        
        return displayedColumns
            .filter(cd => !isMobile || cd.showMobile)
            .map(cd => cd.def);
    }
}
