import { NgModule } from '@angular/core';
import { FuseSharedModule } from '@fuse/shared.module';

import { MatButtonModule, MatDialogModule, MatToolbarModule } from '@angular/material';

import { TranslateModule } from '@ngx-translate/core';

import { IntegAcceptanceTermDialogComponent } from './integ-acceptance-term-dialog.component';

@NgModule({
    declarations: [
        IntegAcceptanceTermDialogComponent
    ],
    imports: [
        FuseSharedModule,
        
        MatDialogModule,
        MatButtonModule,
        MatToolbarModule,

        TranslateModule
    ],
    entryComponents: [
        IntegAcceptanceTermDialogComponent
    ],
})
export class IntegAcceptanceTermDialogModule
{
}