import { Company } from 'app/main/configurations/companies/company.model';
import { Role } from 'app/main/configurations/roles/role.model';

export class AuthUser
{
    id: string;
    name: string;
    email: string;
    token: string;
    type: string;
    avatar: string;
    role?: Role;
    company: Company;
    isApp: boolean;

    constructor(user?)
    {
        user = user || {};
        this.id = user.id || '';
        this.name = user.name || '';
        // this.images = user.images || [];
        this.email = user.email || '';
        this.token = user.token || '';
        this.type = user.type || '';
        this.role = user.role || {};
        this.company = user.company || '';
        this.avatar = user.avatar || '';
        this.isApp = user.isApp || false;
    }

}
