import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';

import { environment } from 'app/environment/environment';
import { AuthenticationService } from 'app/auth/authentication.service';

import { Customer } from 'app/main/model/customer.model';

@Injectable()
export class LeadService
{
    apiUrl = environment.apiUrl + "/lead";

    lead: Customer;
    onLeadChanged: BehaviorSubject<any>;

    constructor(
        private _httpClient: HttpClient,
        private _authService: AuthenticationService
    )
    {
        this.onLeadChanged = new BehaviorSubject({});
    }

    find(lead: any, isCustomer: boolean): Promise<any> {
        let request = {
            id: lead.id,
            isCustomer: isCustomer
        };

        return new Promise((resolve, reject) => {
            this._httpClient.post(this.apiUrl + "/find", {...request}).subscribe((response: any) => {
                this.lead = response;
                this.onLeadChanged.next(this.lead);
                resolve(response);
            }, reject);
        });
    }
    
    contactInterested(lead: any): Promise<any> {
        let request = {
            lead: lead
        };

        return new Promise((resolve, reject) => {
            this._httpClient.post(this.apiUrl + "/contact/interested", {...request}).subscribe((response: any) => {
                this.lead = response;
                this.onLeadChanged.next(this.lead);
                resolve(response);
            }, reject);
        });
    }
    
    findLeadForm(lead?: any, leadFormKey?: string): Promise<any> {
        let request: any = {};

        if(lead) {
            request.lead = lead;
            request.viewed = false;
        }

        if(leadFormKey) {
            request.leadFormKey = leadFormKey;
            request.viewed = true;
        }

        return new Promise((resolve, reject) => {
            this._httpClient.post(this.apiUrl + "/form/find", {...request}).subscribe((response: any) => {
                resolve(response);
            }, reject);
        });
    }

    sendLeadForm(lead): Promise<any> {
        let request = {
            lead: lead
        };

        return new Promise((resolve, reject) => {
            this._httpClient.post(this.apiUrl + "/form/create", {...request}).subscribe((response: any) => {
                resolve(response);
            }, reject);
        });
    }
    
    leadFormFulfillment(leadForm): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post(this.apiUrl + "/form/fulfillment", {...leadForm}).subscribe((response: any) => {
                resolve(response);
            }, reject);
        });
    }

    acceptLead(lead): Promise<any> {
        let request = {
            lead: lead,
            realtor: lead.realtor
        };

        return new Promise((resolve, reject) => {
            this._httpClient.put(this.apiUrl + "/attendance/accept", request).subscribe((response: any) => {
                resolve(response);
            }, reject);
        });
    }
    
    declineLead(lead, additionalInformation?): Promise<any> {
        let request = {
            lead: lead,
            additionalInformation: additionalInformation
        };

        return new Promise((resolve, reject) => {
            this._httpClient.put(this.apiUrl + "/attendance/decline", request).subscribe((response: any) => {
                resolve(response);
            }, reject);
        });
    }

    createCustomerLog(lead, additionalInformation): Promise<any> {
        let request = {
            lead: lead,
            additionalInformation: additionalInformation
        };

        return new Promise((resolve, reject) => {
            this._httpClient.post(this.apiUrl + "/note/create", request).subscribe((response: any) => {
                resolve(response);
            }, reject);
        });
    }
    
    deleteCustomerLog(customerLog): Promise<any> {
        let request = {
            customerLog: customerLog
        };

        return new Promise((resolve, reject) => {
            this._httpClient.put(this.apiUrl + "/note/delete", request).subscribe((response: any) => {
                resolve(response);
            }, reject);
        });
    }

    reactivateFromStandby(lead, additionalInformation): Promise<any> {
        let request = {
            lead: lead,
            additionalInformation: additionalInformation
        };

        return new Promise((resolve, reject) => {
            this._httpClient.put(this.apiUrl + "/standby/reactivate", request).subscribe((response: any) => {
                resolve(response);
            }, reject);
        });
    }

    standby(lead, nextAttendanceDate, additionalInformation): Promise<any> {
        let request = {
            lead: lead,
            nextAttendanceDate: nextAttendanceDate,
            additionalInformation: additionalInformation
        };

        return new Promise((resolve, reject) => {
            this._httpClient.put(this.apiUrl + "/standby", request).subscribe((response: any) => {
                resolve(response);
            }, reject);
        });
    }

    disregardLead(lead, leadStatusKey: string, leadSubstatus, additionalInformation, relatedLead): Promise<any> {
        let request = {
            lead: lead,
            leadStatusKey: leadStatusKey,
            leadSubstatus: leadSubstatus,
            additionalInformation: additionalInformation,
            relatedLead: relatedLead
        };

        return new Promise((resolve, reject) => {
            this._httpClient.put(this.apiUrl + "/disregard", request)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    distributeLead(lead, realtor, additionalInfo?): Promise<any> {
        let request = {
            lead: lead,
            realtor: realtor,
            additionalInformation: additionalInfo
        };

        return new Promise((resolve, reject) => {
            this._httpClient.put(this.apiUrl + "/distribute", request)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    disregardPendentLead(lead: Customer): Promise<any> {
        let user = this._authService.currentUserValue;

        let request = {
            customer: lead,
            additionalInformation: lead.additionalInformation,
            customerSubstatus: lead.customerSubstatus,
            user: user
        };

        return new Promise((resolve, reject) => {
            this._httpClient.put(this.apiUrl + "/disregardPendent", request)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }
    
    listAudits(customer): Promise<any> {
        let params = {
            id: customer.id
        };

        return new Promise((resolve, reject) => {
            this._httpClient.get(this.apiUrl + "/audit", {params: params}).subscribe((response: any) => {
                resolve(response);
            }, reject);
        });
    }

    updateLead(lead): Promise<any> {
        let user = this._authService.currentUserValue;

        let request = {
            lead: lead,
            user: user
        };

        return new Promise((resolve, reject) => {
            this._httpClient.put(this.apiUrl + "/update", request)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    createLead(lead: any, realtor: boolean): Promise<any> {
        let request: any = {
            lead: lead
        };

        if(realtor) {
            request.realtor = this._authService.currentUserValue;
        }

        return new Promise((resolve, reject) => {
            this._httpClient.post(this.apiUrl + "/create", request).subscribe((response: any) => {
                resolve(response);
            }, reject);
        });
    }

    findByPrimaryDocument(primaryDocument: string): Promise<any> {
        let request = {
            primaryDocument: primaryDocument
        };

        return new Promise((resolve, reject) => {
            this._httpClient.post(this.apiUrl + '/find', {...request}).subscribe((response: any) => {
                resolve(response);
            });
        });
    }
}