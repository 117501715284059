import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { Router } from '@angular/router';

import { MileageService } from 'app/main/mileage/mileage.service';

export interface Race {
    name: String;
    prizes: Prize[];
    standings: UserStanding[];
    finalDate: Date;
    isCompany: boolean;
}

export interface Prize {
    description: String;
    position: number;
}

export interface UserStanding {
    name: String;
    position: number;
    points: number;
    isCurrentUser: boolean;
}

@Component({
    selector: "mileage-card",
    templateUrl: "./mileage-card.component.html",
    styleUrls: ["./mileage-card.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class MileageCardComponent implements OnInit {
    racesData: Race[] = [];
    currentUserPoints: number;
    featuredProduct: String;

    selectedTab = 0;

    constructor(
        private _mileageService: MileageService,
        private router: Router
    ) {}

    ngOnInit() {
        this._mileageService.findUserHomeMileage().subscribe((response => {
            this.currentUserPoints = response.balance;
            this.featuredProduct = response.featuredProduct ? response.featuredProduct.description : null;
            this.racesData = this.getFormattedRaces(response.currentRaces);

            if(!this.racesData || this.racesData.length == 0) {
                this.selectedTab = 1;
            }
        }));
    }

    getFormattedPrizes(prizes: Prize[]): String {
        if(!prizes || prizes.length === 0) {
            return '';
        }

        let formattedPrizes = '';

        prizes.forEach((prize, index) => {
            formattedPrizes += prize.position + '° ' + prize.description;
            formattedPrizes += index === prizes.length - 1 ? '' : ', '
        })

        return formattedPrizes;
    }

    getFormattedName(name: string): string {
        let splittedName = name.split(" ");

        return splittedName[0] + " " + splittedName[splittedName.length-1];
    }


    getFormattedRaces(currentRaces: any[]): Race[] {
        if(currentRaces) {
            return currentRaces.map(race => {
                let formattedRace: Race = {
                    ...race,
                    standings: this.getStandings(race)
                }

                return formattedRace;
            });
        }
    }

    getStandings( race: Race ): UserStanding[] {
        let currentUserStanding = race.standings.find( standing => standing.isCurrentUser );

        if( currentUserStanding ) {
            let standings = race.standings;

            if(standings && standings[0] != currentUserStanding) {
                standings = race.standings.slice(0, 1);
                standings.splice(1, 0, currentUserStanding);
            } else if(standings) {
                standings = race.standings.slice(0,2);
            }

            return standings;
        } else {
            return race.standings.slice(0, 2);
        }
    }

    viewMyUnities(): void {
        this.router.navigate(['/fidelity/home']);
    }
}
