export const locale = {
    lang: 'en',
    data: {
        'REAL-ESTATE': {
            'REAL-ESTATE': 'Real Estate',
            'REAL-ESTATES': 'Real Estates',
            'SEARCH' : 'Search for real estate',
            'DELETE-ALL-SELECTED' : 'Are you sure you want to delete all selected real estates?'
        },
        'GENERAL': {
        	'SELECT-ALL' : 'Select all',
        	'DESELECT-ALL' : 'Deselect all',
        	'SELECTED' : 'Selected',
        	'BACK' : 'Back',
        	'NAME' : 'Name',
        	'PHONE' : 'Phone',
        	'EMAIL' : 'Email',
        	'STREET' : 'Street',
        	'REMOVE' : 'Remove',
            'DELETE-CONFIRMATION' : 'Are you sure you want to delete?'
        },
        'USER': {
            'USER': 'User',
            'USERS': 'Users',
        },
        'NAV': {
            'CONFIGURATIONS': 'Configurations',
            'REAL-ESTATE'        : {
                'TITLE': 'Real Estates',
                'BADGE': ''
            },
            'GENERAL'        : {
                'TITLE': 'General',
                'BADGE': ''
            },
            'SAMPLE'        : {
                'TITLE': 'Sample',
                'BADGE': '25'
            }
        }
    }
};
