import { FuseUtils } from '@fuse/utils';

import { CompanyType } from 'app/main/configurations/company-types/company-type.model';

import { Menu } from 'app/main/configurations/menu/menu.model';
import { Action } from 'app/main/configurations/action/action.model';
import { Email } from 'app/main/model/email.model';
import { Card } from 'app/main/model/card.model';
import { Notification } from 'app/main/model/notification.model';
import { Shortcut } from 'app/main/model/shortcut.model';

export class Role
{
    id: string;
    name: string;
    description: string;
    companyType: CompanyType;
    companyRequired: boolean;
    menus: Menu[];
    actions: Action[];
    cards: Card[];
    shortcuts : Shortcut[]
    emails: Email[];
    notifications: Notification[];

    /**
     * Constructor
     *
     * @param role
     */
    constructor(role?)
    {
        role = role || {};
        this.id = role.id || '';
        this.name = role.name || '';
        this.description = role.description || '';
        this.companyType = role.companyType || '';
        this.companyRequired = role.companyRequired || '';
        this.menus = role.menus || '';
        this.actions = role.actions || '';
        this.emails = role.emails || '';
        this.cards = role.cards || '';
        this.shortcuts = role.shortcuts || '';
        this.notifications = role.notifications || '';
    }
}
