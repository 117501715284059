import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';

import { AuthUser } from './model/auth-user.model';
import { User } from 'app/main/configurations/user/user.model';
import { UserMenu } from './model/user-menu.model';
import { Action } from 'app/main/configurations/action/action.model';
import { Shortcut } from 'app/main/model/shortcut.model';
import { Card } from 'app/main/model/card.model';
import { Development } from 'app/main/configurations/development/development.model';
import { AnyDocument } from 'app/main/model/any-document.model';

import { environment } from 'app/environment/environment'; 
import { IntegCustomer } from 'app/main/model/integ/integ-customer.model';
import { Role } from 'app/main/configurations/roles/role.model';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { FuseNavigation } from '@fuse/types';
import { TranslateService } from '@ngx-translate/core';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private currentUserSubject: BehaviorSubject<AuthUser>;
    public currentAvatarSubject: BehaviorSubject<AnyDocument>;
    private currentMenuSubject: BehaviorSubject<UserMenu>;
    private currentShortcutsSubject: BehaviorSubject<Shortcut[]>;
    private currentCardsSubject: BehaviorSubject<Card[]>;
    private userActionsSubject: BehaviorSubject<Action[]>;
    private currentIntegCustomerSubject: BehaviorSubject<IntegCustomer>;
    private currentIntegCustomerLogoSubject: BehaviorSubject<AnyDocument>;
    private currentDevelopmentSubject: BehaviorSubject<Development>;
    private currentDevelopmentLogoSubject: BehaviorSubject<AnyDocument>;
    private currentDevelopmentImplantationSubject: BehaviorSubject<AnyDocument>;
    public currentUser: Observable<AuthUser>;
    public currentUserInfo: Observable<User>;
    public currentMenu: Observable<UserMenu>;
    public currentShortcuts: Observable<Shortcut[]>;
    public currentCards: Observable<Card[]>;
    public userActionKeys: Observable<Action[]>;
    public currentIntegCustomer: Observable<IntegCustomer>;
    public currentIntegCustomerLogo: Observable<AnyDocument>;
    public currentDevelopment: Observable<Development>;
    public currentAvatar: Observable<AnyDocument>;
    public currentDevelopmentLogo: Observable<AnyDocument>;
    public currentDevelopmentImplantation: Observable<AnyDocument>;
    private apiUrl = environment.apiUrl;

    constructor(
        private _httpClient: HttpClient,
        private _fuseNavigationService: FuseNavigationService,
        private _translate : TranslateService
    ) {
        this.currentUserSubject = new BehaviorSubject<AuthUser>(
            JSON.parse(localStorage.getItem('currentUser'))
        );
        this.currentUser = this.currentUserSubject.asObservable();

        this.currentAvatarSubject = new BehaviorSubject<AnyDocument>(
            JSON.parse(localStorage.getItem('avatar'))
        );
        this.currentAvatar = this.currentAvatarSubject.asObservable();

        this.currentShortcutsSubject = new BehaviorSubject<Shortcut[]>(
            JSON.parse(localStorage.getItem('userShortcuts'))
        );
        this.currentShortcuts = this.currentShortcutsSubject.asObservable();
        
        this.currentCardsSubject = new BehaviorSubject<Card[]>(
            JSON.parse(localStorage.getItem('userCards'))
        );
        this.currentCards = this.currentCardsSubject.asObservable();

        this.currentMenuSubject = new BehaviorSubject<UserMenu>(
            JSON.parse(localStorage.getItem('userMenu'))
        );
        this.currentMenu = this.currentMenuSubject.asObservable();

        this.userActionsSubject = new BehaviorSubject<Action[]>(
            JSON.parse(localStorage.getItem('userActionKeys'))
        );
        this.userActionKeys = this.userActionsSubject.asObservable();

        this.currentIntegCustomerSubject = new BehaviorSubject<IntegCustomer>(
            JSON.parse(localStorage.getItem('selectedIntegCustomer'))
        );
              
        this.currentIntegCustomer = this.currentIntegCustomerSubject.asObservable();

        this.currentIntegCustomerLogoSubject = new BehaviorSubject<AnyDocument>(
            JSON.parse(localStorage.getItem('selectedIntegCustomerLogo'))
        );

        this.currentIntegCustomerLogo = this.currentIntegCustomerLogoSubject.asObservable();

        this.currentDevelopmentSubject = new BehaviorSubject<Development>(
            JSON.parse(localStorage.getItem('selectedDevelopment'))
        );
              
        this.currentDevelopment = this.currentDevelopmentSubject.asObservable();

        this.currentDevelopmentLogoSubject = new BehaviorSubject<AnyDocument>(
            JSON.parse(localStorage.getItem('selectedDevelopmentLogo'))
        );

        this.currentDevelopmentLogo = this.currentDevelopmentLogoSubject.asObservable();

        this.currentDevelopmentImplantationSubject = new BehaviorSubject<AnyDocument>(
            JSON.parse(localStorage.getItem('selectedDevelopmentImplantation'))
        );

        this.currentDevelopmentImplantation = this.currentDevelopmentImplantationSubject.asObservable();
    }

    public get currentUserValue(): AuthUser {
        return this.currentUserSubject.value;
    }

    public get currentUserMenu() : UserMenu {
        return this.currentMenuSubject.value;
    }

    public get currentShortcutsValue() : Shortcut[] {
        return this.currentShortcutsSubject.value;
    }
    
    public get currentCardsValue() : Card[] {
        return this.currentCardsSubject.value;
    }

    public get currentAvatarValue(): AnyDocument {
        return this.currentAvatarSubject.value;
    }

    public get userActionValues(): Action[] {
        return this.userActionsSubject.value;
    }

    public get currentIntegCustomerValue(): IntegCustomer {
        return this.currentIntegCustomerSubject.value;
    }

    public get currentIntegCustomerLogoValue(): AnyDocument {
        return this.currentIntegCustomerLogoSubject.value;
    }

    public get currentDevelopmentValue(): Development {
        return this.currentDevelopmentSubject.value;
    }

    public get currentDevelopmentLogoValue(): AnyDocument {
        return this.currentDevelopmentLogoSubject.value;
    }

    public get currentDevelopmentImplantationValue(): AnyDocument {
        return this.currentDevelopmentImplantationSubject.value;
    }

    confirmMail(key): Promise<any> {
        let request = {
            emailConfirmationKey: key
        };

        return new Promise((resolve, reject) => {
            this._httpClient.post(this.apiUrl + '/user/confirmation', request).subscribe((response: any) => {
                resolve(response);
            });
        });
    }

    confirmMailChange(key): Promise<any> {
        let request = {
            emailConfirmationKey: key
        };

        return new Promise((resolve, reject) => {
            this._httpClient.post(this.apiUrl + '/user/email/change/confirmation', request).subscribe((response: any) => {
                resolve(response);
            });
        });
    }

    changePassword(userId, password, newPassword) : Promise<any> {
        let request = {
            id: userId,
            password: password,
            newPassword: newPassword
        }

        return new Promise((resolve, reject) => {
            this._httpClient.post(this.apiUrl + '/user/changePassword', request).subscribe((response: any) => {
                resolve(response);
            });
        });
    }

    firstAccess(userId, password, newPassword) : Promise<any> {
        let request = {
            id: userId,
            password: password,
            newPassword: newPassword
        }

        return new Promise((resolve, reject) => {
            this._httpClient.post(this.apiUrl + '/user/firstAccess', request).subscribe((response: any) => {
                if (response && response.token) {
                    this.registerUser(response);
                }
                resolve(response);
            });
        });
    }

    login(loginRequest): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post(this.apiUrl + '/user/login', loginRequest).subscribe((response: any) => {
                // login successful if there's a jwt token in the response
                if(response && response.token) {
                    this.registerUser(response);
                }
                resolve(response);
            });
        });
    }

    forgotPassword(forgotPasswordRequest): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post(this.apiUrl + '/user/forgotPassword', forgotPasswordRequest).subscribe((response: any) => {
                resolve(response);
            });
        });
    }

    registerUser(loginResponse): void {
        if(!loginResponse.role) {
            loginResponse.role = new Role();
        }

        let authUser = new AuthUser(loginResponse);
       // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem('currentUser', JSON.stringify(loginResponse));
        localStorage.setItem('deviceId', loginResponse.deviceId);
        
        this.registerAvatar(loginResponse.avatar);

        this.currentUserSubject.next(authUser);
    }

    registerAvatar(avatar): void {
        if(avatar) {
            localStorage.setItem('avatar', JSON.stringify(avatar));
        }

        this.currentAvatarSubject.next(avatar);
    }

    registerPermissions(): void {
        this.getActions();
        this.getShortcuts();
        this.getCards();
        this.registerMenu();
    }

    getShortcuts(): void {
        let user = this.currentUserValue;

        this._httpClient.get(this.apiUrl + '/shortcut/listInRole/' + user.role.id).subscribe((response: Shortcut[]) => {
            localStorage.setItem('userShortcuts', JSON.stringify(response));
            this.currentShortcutsSubject.next(response);
        });
    }
    
    getCards(): void {
        let user = this.currentUserValue;

        this._httpClient.get(this.apiUrl + '/card/listInRole/' + user.role.id).subscribe((response: Card[]) => {
            localStorage.setItem('userCards', JSON.stringify(response));
            this.currentCardsSubject.next(response);
        });
    }

    hasCard(cardKey): boolean {
        if(this.currentCardsValue) {
            let card = this.currentCardsValue.find(card => card.cardKey == cardKey);
            if(card) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    getActions(): void {
        let user = this.currentUserValue;

        this._httpClient.get(this.apiUrl + '/action/listByRole/' + user.role.id).subscribe((response: Action[]) => {
            localStorage.setItem('userActionKeys', JSON.stringify(response));
            this.userActionsSubject.next(response);
        });
    }

    hasAction(searchActionKey): boolean {
        if(this.apiUrl.includes('localhost')) {
            return true;
        }

        if(this.userActionValues) {
            let action = this.userActionValues.find(action => action.actionKey == searchActionKey);
            if(action) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    getMenu(): Promise<any> {
        let user = this.currentUserValue;

        return new Promise((resolve, reject) => {
            this._httpClient.get(this.apiUrl + "/menu/list/" + user.role.id).subscribe((response: any) => {
                localStorage.setItem('userMenu', JSON.stringify(response));
                this.currentMenuSubject.next(response);
                resolve(response);
            }, reject);
        });
    }

    registerMenu(): void {
        this.getMenu().then((response) => {
            let menus = this.translateMenus(response);
            const navigation: FuseNavigation[] = menus;

            // Unregister the "main" key of the menu so we can register next
            this._fuseNavigationService.unregister('main');

            // Register the navigation to the service
            this._fuseNavigationService.register('main', navigation);

            // Set the main navigation as our current navigation
            this._fuseNavigationService.setCurrentNavigation('main');
        });
    }

    translateMenus(menusResponse): any[] {
        let menus = [];
        if(menusResponse) {
            for(let menu of menusResponse) {
                if(menu.children) {
                    menu.children = this.translateMenus(menu.children);
                }
                menu.title = this._translate.instant(menu.translate);
                menus.push(menu);
            }
        }

        return menus;
    }

    selectIntegCustomer(integCustomer: IntegCustomer): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get(this.apiUrl + "/integ/customer/find/" + integCustomer.id).toPromise().then((response: any) => {
                if(response) {
                    let user = this.currentUserValue;
                    user.role = response.role;
                    this.currentUserSubject.next(user);
                    localStorage.setItem('currentUser', JSON.stringify(user));

                    let selectedIntegCustomer = new IntegCustomer(response);  
                
                    if(selectedIntegCustomer.logo) {
                        localStorage.setItem('selectedIntegCustomerLogo', JSON.stringify(selectedIntegCustomer.logo));
                        this.currentIntegCustomerLogoSubject.next(response.logo);
                    }
                    
                    selectedIntegCustomer.logo = null; 

                    localStorage.setItem('selectedIntegCustomer', JSON.stringify(selectedIntegCustomer)) ; 
                    this.currentIntegCustomerSubject.next(selectedIntegCustomer);

                    this.registerPermissions();

                    resolve(response);
                } else {
                    throw "ERROR.NO-ROLE-ASSIGNED";
                }
            }).catch((error) => {
                reject(error);
            });
        });
    }

    selectDevelopment(development): Promise<any> {
        let request = {
            id: development.id
        };

        return new Promise((resolve, reject) => {
            this._httpClient.post(this.apiUrl + "/development/find", {...request}).subscribe((response: any) => {
                let selectedDevelopment = new Development(response);  
            
                localStorage.setItem('selectedDevelopmentLogo', JSON.stringify(selectedDevelopment.logo));
                this.currentDevelopmentLogoSubject.next(response.logo);
                
                localStorage.setItem('selectedDevelopmentImlantation', JSON.stringify(selectedDevelopment.implantation));
                this.currentDevelopmentImplantationSubject.next(response.implantation);
                
                selectedDevelopment.logo = null; 
                selectedDevelopment.implantation = null;
                selectedDevelopment.cover = null;

                localStorage.setItem('selectedDevelopment', JSON.stringify(selectedDevelopment));
                this.currentDevelopmentSubject.next(selectedDevelopment);
                resolve(response);
            }, reject);
        });
    }

    isApp(): boolean {
        return localStorage.getItem('deviceId') != null && localStorage.getItem('deviceId') != 'null' && localStorage.getItem('deviceId') != '';
    }

    getDeviceId(): string {
        return localStorage.getItem('deviceId');
    }

    getMobileOs(): string {
        const ua = navigator.userAgent
        if (/android/i.test(ua)) {
            return "ANDROID";
        }
        else if (/iPad|iPhone|iPod/.test(ua)) {
            return "IOS";
        }

        return "Other";
    }

    deactivateAccount(): Promise<any> {
        let request = {
            id: this.currentUserValue.id
        };

        return new Promise((resolve, reject) => {
            this._httpClient.put(this.apiUrl + "/user/delete", {...request}).subscribe((response: any) => {
                resolve(response);
            }, reject);
        });
    }

    getLogoutRoute(): string {
        let route = '/auth';

        if(this.isApp()) {
            if(this.getMobileOs() == 'IOS') {
                route = route + '/calculator/' + this.getDeviceId();
            } else {
                route = route + '/login/' + this.getDeviceId();
            }
        } else {
            route = route + '/login';
        }

        return route;
    }

    logout() {
        // remove user and menu from local storage to log user out
        localStorage.removeItem('currentUser');
        localStorage.removeItem('userMenu');
        localStorage.removeItem('userActionKeys');
        localStorage.removeItem('userShortcuts');
        localStorage.removeItem('selectedIntegCustomer');
        localStorage.removeItem('selectedIntegCustomerLogo');
        localStorage.removeItem('avatar');
        localStorage.removeItem('selectedDevelopment');
        localStorage.removeItem('selectedDevelopmentLogo'); 
        localStorage.removeItem('selectedDevelopmentImplantation');

        this.currentUserSubject.next(null);
        this.currentMenuSubject.next(null);
        this.userActionsSubject.next(null);
        this.currentShortcutsSubject.next(null);
        this.currentIntegCustomerSubject.next(null);
        this.currentIntegCustomerLogoSubject.next(null);
        this.currentDevelopmentSubject.next(null);
        this.currentDevelopmentLogoSubject.next(null);
        this.currentDevelopmentImplantationSubject.next(null);
        this.currentAvatarSubject.next(null);
    }
}