import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';

import { environment } from 'app/environment/environment';
import { AuthenticationService } from 'app/auth/authentication.service';

@Injectable()
export class BirthdayService
{
    apiUrl = environment.apiUrl + "/user";
    onBirthdaysChanged: BehaviorSubject<any>;

    constructor(
        private _httpClient: HttpClient,
        private _authService: AuthenticationService
    )
    {
        this.onBirthdaysChanged = new BehaviorSubject({});
    }

    list(): Promise<any> {
        if(this._authService.currentUserValue && this._authService.currentUserValue.id) {
            return new Promise((resolve, reject) => {
                this._httpClient.get(this.apiUrl + "/birthday").subscribe((response: any) => {
                    this.onBirthdaysChanged.next(response);
                    resolve(response);
                }, reject);
            });
        } else {
            return new Promise((resolve, reject) => {
                this.onBirthdaysChanged.next(null);
            });
        }
    }
}
