import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';

import { AuthenticationService } from 'app/auth/authentication.service';
import { UserNotificationsResponse } from 'app/main/model/user-notifications-response.model';
import { UserNotification } from 'app/main/model/user-notification.model';

import { environment } from 'app/environment/environment';

@Injectable()
export class UserNotificationService
{
    apiUrl = environment.apiUrl + "/userNotification";
    
    onNotificationsChanged: BehaviorSubject<any>;

    userNotificationsResponse: UserNotificationsResponse;

    pageNumber : number ;

    isQuickPanel; 
    
    constructor(
        private _httpClient: HttpClient,
        private _authService: AuthenticationService
    )
    {
        // Set the defaults
        this.onNotificationsChanged = new BehaviorSubject([]);
    }

    getNotifications(pageNumber, isQuickPanel): Promise<any> {
        if(this._authService.currentUserValue && this._authService.currentUserValue.id) {
            let userId = JSON.parse(this._authService.currentUserValue.id);

            let request = {
                user: userId,
                page: pageNumber,
                quickPanel: isQuickPanel
            };

            if(userId) {
                return new Promise((resolve, reject) => {
                        this._httpClient.post(this.apiUrl + '/list', request)
                            .subscribe((response: any) => {
                                this.userNotificationsResponse = response;
                                this.onNotificationsChanged.next(this.userNotificationsResponse);
                                resolve(this.userNotificationsResponse);
                            }, reject);
                    }
                );
            } else {
                return new Promise((resolve, reject) => {
                    this.onNotificationsChanged.next(null);
                });
            }
        } else {
            return new Promise((resolve, reject) => {
                this.onNotificationsChanged.next(null);
            });
        }
    }

    markAsViewed(notifications: UserNotification[], pageNumber, isQuickPanel): Promise<any> {
       let request = {
            userNotifications: notifications
        };

        return new Promise((resolve, reject) => {
            this._httpClient.post(this.apiUrl + '/view/selected', {...request})
                .subscribe((response: any) => {
                    this.getNotifications(pageNumber, isQuickPanel);
                    resolve(response);
                }, reject);
        });
    }

     markAllAsViewed() : Promise<any> {
        let id = this._authService.currentUserValue.id;

        return new Promise((resolve, reject) => {
            this._httpClient.post(this.apiUrl + '/view/all', id)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        }); 
     }        
 }
