import { NgModule } from '@angular/core';
import { MatBadgeModule, MatButtonModule, MatDividerModule, MatIconModule, MatListModule, MatSlideToggleModule } from '@angular/material';
import { MatTabsModule } from '@angular/material/tabs';
import { RouterModule } from '@angular/router';

import { FuseSharedModule } from '@fuse/shared.module';

import { QuickPanelComponent } from 'app/layout/components/quick-panel/quick-panel.component';

import { TranslateModule } from '@ngx-translate/core';

import { BirthdayService } from 'app/main/service/birthday.service';
import { BirthdayListComponent } from 'app/layout/components/quick-panel/birthday-list/birthday-list.component';

@NgModule({
    declarations: [
        QuickPanelComponent,
        BirthdayListComponent
    ],
    imports     : [
        MatBadgeModule,
        MatButtonModule,
        MatDividerModule,
        MatIconModule,
        MatListModule,
        MatSlideToggleModule,
        MatTabsModule,

        RouterModule,

        FuseSharedModule,
        TranslateModule
    ],
    exports: [
        QuickPanelComponent
    ],
    providers: [
        BirthdayService
    ]
})
export class QuickPanelModule
{
}
