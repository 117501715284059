import { Component, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material';

import { trigger, style, animate, transition } from '@angular/animations';

import { TranslateService } from '@ngx-translate/core';

import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

import { locale as english } from 'app/i18n/en';
import { locale as portuguese } from 'app/i18n/pt';

import { MobileConfigService } from 'app/main/common/mobile-config-service';

import { User } from 'app/main/configurations/user/user.model';
import { AuthUser } from 'app/auth/model/auth-user.model';
import { Development } from 'app/main/configurations/development/development.model';

import { AuthenticationService } from 'app/auth/authentication.service';
import { UserService } from 'app/main/configurations/user/user.service';
import { DevelopmentsService } from 'app/main/configurations/developments/developments.service';

import { ShareDialogComponent } from 'app/component/share-dialog/share-dialog.component';
import { DevelopmentCatalogComponent } from 'app/main/sales/development-catalog/development-catalog.component';
import { BirthdayService } from '../service/birthday.service';

@Component({
    selector   : 'home',
    templateUrl: './home.component.html',
    styleUrls  : ['./home.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: [
        trigger(
            'openClose', 
            [
                transition(
                    ':enter', 
                    [
                        style({ height: 0, opacity: 0 }),
                            animate('0.5s ease-out', style({ height: '*', opacity: 1 }))
                        ]
                    ),
                transition(
                    ':leave', 
                    [
                        style({ height: '*', opacity: 1 }),
                        animate('0.5s ease-in', style({ height: 0, opacity: 0 }))
                    ]
                )
            ]
        )
    ]
})
export class HomeComponent {
    shareDialogRef: MatDialogRef<ShareDialogComponent>;
    dialogRef: any;

    navigation: any;
    currentUser: AuthUser;
    userFromApi: User;
    today: Date;

    shortcutItems: any[];

    hasMileageRealtorCard: boolean = false;
    hasCommissionsRealtorCard: boolean = false;
    hasDevelopmentCatalogCard: boolean = false;

    developmentList: any[] = [];

    constructor(
        private _translateService: TranslateService,
        private _authService: AuthenticationService,
        private _userService: UserService,
        private _developmentsService: DevelopmentsService,
        public _matDialog: MatDialog,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private translate: TranslateService,
        private router: Router,
        private _mobileService: MobileConfigService,
        private _birthdayService: BirthdayService,
        private route: ActivatedRoute
    )
    {
        // Add languages
        this._translateService.addLangs(['en', 'pt']);

        // Set the default language
        this._translateService.setDefaultLang('pt');

        // Set the navigation translations
        this._fuseTranslationLoaderService.loadTranslations(english, portuguese);

        // Use a language
        this._translateService.use('pt');

        setTimeout(() => {
            this._translateService.setDefaultLang('en');
            this._translateService.setDefaultLang('pt');
        });

        this.currentUser = this._authService.currentUserValue;

        this.loadDevelopments();
        this.today = new Date();
    }

    ngOnInit() {
        if(this.currentUser) {
            this._userService.findById(this.currentUser.id).then(user => { 
                this.userFromApi = user;
            });
        }

        this.loadShortcuts();
        this.loadBirthdays();
        this.loadActions();
    }

    loadActions(): void {
        this._authService.currentCards.subscribe((cards) => {
            this.hasMileageRealtorCard = false;
            this.hasCommissionsRealtorCard = false;
            this.hasDevelopmentCatalogCard = false;
            
            if(cards) {
                for(let card of cards) {
                    if(card.cardKey == 'MILEAGE_REALTOR') {
                        this.hasMileageRealtorCard = this._authService.hasCard('MILEAGE_REALTOR');
                    } else if(card.cardKey == 'COMMISSIONS_REALTOR') {
                        this.hasCommissionsRealtorCard = this._authService.hasCard('COMMISSIONS_REALTOR');
                    } else if(card.cardKey == 'DEVELOPMENT_CATALOG') {
                        this.hasDevelopmentCatalogCard = this._authService.hasCard('DEVELOPMENT_CATALOG');
                    }
                }
            }
        });
    }

    loadBirthdays(): void {
        this._birthdayService.list();
    }

    loadShortcuts(): void {
        let salesTitle = this.translate.instant('SALES.SALES');;
        let documentsTitle = this.translate.instant('MEDIA.DOCUMENTS');
        let mediaTitle = this.translate.instant('MEDIA.MEDIA');
        let mileageTitle = this.translate.instant('MILEAGE.MILEAGE');

        // User's shortcut items
        this._authService.currentShortcuts.subscribe((response) => {
            this.shortcutItems = response;
        });
    }

    shortcutClick(shortcut): void {
        this.router.navigate([shortcut.url]);
    }

    loadDevelopments(): void {
        if(this._authService.currentUserValue && this._authService.currentIntegCustomerValue) {
            let user = this._authService.currentUserValue;
            
            if(user.role) {
                this._developmentsService.getDevelopmentsInRole(user.role.id).then((response) => {
                    for(let development of response) {
                        if(development.developmentType.developmentTypeKey == 'DEVELOPMENT') {
                            this.developmentList.push(development);
                        }
                    }
                });
            } else {
                this._authService.logout();

                let route = this._authService.getLogoutRoute();

                this.router.navigate([route]);
            }
        }
    }

    toggleDevelopment(index: number): void {
        for(let i: number = 0; i <= (this.developmentList.length - 1); i++) {
            if(i != index) {
                this.developmentList[i].isExpanded = false;
            }
        }

        this.developmentList[index].isExpanded = !this.developmentList[index].isExpanded;
    }

    openDevelopmentCatalog(development: Development): void {
        let top = '100px';

        if(this._mobileService.isMobile()) {
            top = '56px';
        }

        this.dialogRef = this._matDialog.open(DevelopmentCatalogComponent, {
            panelClass: 'development-catalog',
            data: {
                development: development
            },
            position : {
                'top' : top
            },
            disableClose: false
        });

        this.dialogRef.afterClosed().subscribe(result => {
            if ( result ) {
                
            }
            this.dialogRef = null;
        });

        //this.openShareComponent(development);
    }

    openShareComponent(development: Development): void {
        let logoUrl = development.logo ? encodeURI(development.logo.url) : this.translate.instant('GENERAL.UNAVAILABLE');
        let document: any = {};
        document.url = development.logo ? encodeURI(development.logo.url) : this.translate.instant('GENERAL.UNAVAILABLE');

        this.shareDialogRef = this._matDialog.open(ShareDialogComponent, {
            panelClass: 'share-dialog',
            position : {
                'top' : '100px'
            },
            data: {
                document: document
            },
            disableClose: false
        });

        this.shareDialogRef.componentInstance.shareMessage = this.translate.instant('SHARE.DEVELOPMENT-CATALOG') + ' ' + development.name + ' ' + logoUrl;
        this.shareDialogRef.componentInstance.document = document;

        this.shareDialogRef.afterClosed().subscribe(result => {
            if ( result ) {
                
            }
            this.shareDialogRef = null;
        });
    }
}
