import { NgModule } from '@angular/core';
import { FuseSharedModule } from '@fuse/shared.module';

import { MatButtonModule, MatDialogModule, MatIconModule, MatToolbarModule, MatFormFieldModule, MatSelectModule, MatInputModule } from '@angular/material';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NgxMaskModule } from 'ngx-mask';
import { CurrencyMaskModule } from "ng2-currency-mask";

import { TranslateModule } from '@ngx-translate/core';

import { LeadService } from 'app/main/lead/lead.service';
import { TypologyService } from 'app/main/service/typology.service';
import { CustomerEntryMethodService } from 'app/main/service/customer-entry-method.service';
import { DevelopmentsService } from 'app/main/configurations/developments/developments.service';

import { LeadFormDialogComponent } from 'app/main/lead/lead-form-dialog/lead-form-dialog.component';
import { DynamicPhoneMaskModule } from 'app/main/common/dynamic-phone-mask/dynamic-phone-mask.module';

@NgModule({
    declarations: [
        LeadFormDialogComponent
    ],
    imports: [
        FuseSharedModule,
        NgxMaskModule.forRoot(),
        CurrencyMaskModule,
        
        FormsModule,
        ReactiveFormsModule,

        MatDialogModule,
        MatButtonModule,
        MatIconModule,
        MatToolbarModule,
        MatFormFieldModule,
        MatSelectModule,
        MatInputModule,
        DynamicPhoneMaskModule,

        TranslateModule
    ],
    providers    : [
        LeadService,
        TypologyService,
        CustomerEntryMethodService,
        DevelopmentsService
    ],
    entryComponents: [
        LeadFormDialogComponent
    ]
})
export class LeadFormDialogModule
{
}