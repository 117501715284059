import { NgModule } from '@angular/core';
import { MatButtonModule, MatButtonToggleModule, MatDialogModule, MatFormFieldModule, MatIconModule, MatInputModule, MatSnackBarModule, MatToolbarModule, MatTooltipModule } from '@angular/material';

import { FuseSharedModule } from '@fuse/shared.module';

import { TranslateModule } from '@ngx-translate/core';

import { ShareDialogComponent } from 'app/component/share-dialog/share-dialog.component';

@NgModule({
    declarations: [
        ShareDialogComponent
    ],
    imports: [
        MatButtonModule,
        MatButtonToggleModule,
        MatDialogModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatSnackBarModule,
        MatToolbarModule,
        MatTooltipModule,

        FuseSharedModule,

        TranslateModule
    ],
    entryComponents: [
        ShareDialogComponent
    ]
})
export class ShareDialogModule
{
}
