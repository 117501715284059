import { Supplier } from 'app/main/model/supplier.model';
import { Development } from 'app/main/configurations/development/development.model';

export class SupplierDevelopment
{
    supplier: Supplier;
    development: Development;

    constructor(supplierDevelopment?) {
        supplierDevelopment = supplierDevelopment || {};
        this.supplier = supplierDevelopment.supplier || '';
        this.development = supplierDevelopment.development || '';
    }

}
