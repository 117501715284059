import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { MatBadgeModule, MatButtonModule, MatCheckboxModule, MatChipsModule, MatDialogModule, MatDividerModule, MatFormFieldModule, MatIconModule,
    MatInputModule, MatMenuModule, MatToolbarModule, MatTooltipModule, MatTabsModule, MatSlideToggleModule } from '@angular/material';

import { FuseSharedModule } from '@fuse/shared.module';

import { NgxImageGalleryModule } from 'ngx-image-gallery';
import { LightboxModule } from 'ngx-lightbox';

import { AuthGuardService } from 'app/auth/auth-guard.service';
import { UserService } from 'app/main/configurations/user/user.service';
import { PostService } from 'app/main/service/post.service';
import { DevelopmentsService } from 'app/main/configurations/developments/developments.service';
import { MileageService } from 'app/main/mileage/mileage.service';
import { UnitiesService } from 'app/main/user/unity/unities.service';

import { HomeComponent } from 'app/main/home/home.component';
import { NewsComponent } from 'app/main/news/news.component';

import { CommentTimelineComponent } from 'app/main/news/comment-timeline/comment-timeline.component';
import { PostFormDialogComponent } from 'app/main/news/post-form/post-form.component';
import { UmeDialogModule } from 'app/component/ume-dialog/ume-dialog.module';
import { ShareDialogModule } from 'app/component/share-dialog/share-dialog.module';
import { UmeShortcutsModule } from 'app/layout/components/shortcuts/shortcuts.module';
import { DevelopmentCatalogModule } from 'app/main/sales/development-catalog/development-catalog.module';
import { MileageCardComponent } from './mileage-card/mileage-card.component';
import { CommissionsCardComponent } from 'app/main/home/commissions-card/commissions-card.component';
import { BirthdayService } from '../service/birthday.service';

const routes = [
    {
        path     : 'home',
        component: HomeComponent,
        canActivate: [AuthGuardService]
    }
];

@NgModule({
    declarations: [
        HomeComponent,
        NewsComponent,
        PostFormDialogComponent,
        CommentTimelineComponent,
        MileageCardComponent,
        CommissionsCardComponent
    ],
    imports     : [
        RouterModule.forChild(routes),

        TranslateModule,

        FuseSharedModule,

        NgxImageGalleryModule,
        LightboxModule,

        MatBadgeModule,
        MatButtonModule,
        MatCheckboxModule,
        MatChipsModule,
        MatDialogModule,
        MatDividerModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatMenuModule,
        MatSlideToggleModule,
        MatToolbarModule,
        MatTooltipModule,
        MatTabsModule,
        UmeDialogModule,
        UmeShortcutsModule,
        ShareDialogModule,
        DevelopmentCatalogModule
    ],
    exports     : [
        HomeComponent
    ],
    providers    : [
        UserService,
        AuthGuardService,
        PostService,
        DevelopmentsService,
        MileageService,
        UnitiesService,
        BirthdayService
    ],
    entryComponents : [
        PostFormDialogComponent,
        CommentTimelineComponent
    ]
})

export class HomeModule
{
}
