import { NgModule } from '@angular/core';
import { MatButtonModule, MatButtonToggleModule, MatCheckboxModule, MatDialogModule, MatFormFieldModule, MatIconModule, MatInputModule, MatSnackBarModule, MatTabsModule, MatToolbarModule } from '@angular/material';

import { FuseSharedModule } from '@fuse/shared.module';

import { TranslateModule } from '@ngx-translate/core';

import { SalesService }    from 'app/main/sales/sales.service';
import { TowersService } from 'app/main/development/towers/towers.service';
import { SalesTowerService }  from 'app/main/sales/development-catalog/sales-tower.service';
import { DevelopmentCatalogService } from './development-catalog.service';

import { DevelopmentCatalogComponent } from 'app/main/sales/development-catalog/development-catalog.component';

import { ShareDialogModule } from 'app/component/share-dialog/share-dialog.module';

@NgModule({
    declarations: [
        DevelopmentCatalogComponent
    ],
    imports: [
        MatButtonModule,
        MatButtonToggleModule,
        MatCheckboxModule,
        MatDialogModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatSnackBarModule,
        MatTabsModule,
        MatToolbarModule,

        FuseSharedModule,

        ShareDialogModule,

        TranslateModule
    ],
    providers: [
        SalesService,
        TowersService,
        SalesTowerService,
        DevelopmentCatalogService
    ],
    entryComponents: [
        DevelopmentCatalogComponent
    ]
})
export class DevelopmentCatalogModule
{
}
