import { Role } from 'app/main/configurations/roles/role.model';
import { Company } from 'app/main/configurations/companies/company.model';
import { Customer } from 'app/main/model/customer.model';
import { UserDependent } from 'app/main/model/user-dependent.model';
import { UserStatus } from 'app/main/model/user-status.model';

import { AnyDocument } from 'app/main/model/any-document.model';

export class User
{
    id: string;
    name: string;
    postalCode: string;
    street: string;
    number: string;
    complement: string;
    city: string;
    state: string;
    phone: string;
    email: string;
    password: string;
    primaryDocument: string;
    secondaryDocument: string;
    businessIdentifier: string;
    emailConfirmed: boolean;
    emailConfirmationKey: string;
    temporaryPassword: boolean;
    firstAccess: boolean;
    firstAccessDate: Date;
    lastLoginDate: Date;
    role: Role;
    company: Company;
    customer: Customer;
    avatar: AnyDocument;
    birthDate: Date;
    observation: string;
    lastConfirmationMail: Date;
    mileageAccepted: boolean;
    mileageAcceptanceDate: Date;
    dependents: UserDependent[];
    userStatus: UserStatus;
    createdBy: User;
    creationDate: Date;

    constructor(user?)
    {
        user = user || {};
        this.id = user.id || '';
        this.name = user.name || '';
        this.postalCode = user.postalCode || '';
        this.street = user.street || '';
        this.number = user.number || '';
        this.complement = user.complement || '';
        this.city = user.city || '';
        this.state = user.state || '';
        this.phone = user.phone;
        this.email = user.email || '';
        this.password = user.password || '';
        this.primaryDocument = user.primaryDocument || '';
        this.secondaryDocument = user.secondaryDocument || '';
        this.businessIdentifier = user.businessIdentifier || '';
        this.emailConfirmed = user.emailConfirmed || '';
        this.emailConfirmationKey = user.emailConfirmationKey || '';
        this.temporaryPassword = user.temporaryPassword || '';
        this.firstAccess = user.firstAccess || '';
        this.firstAccessDate = user.firstAccessDate || '';
        this.lastLoginDate = user.lastLoginDate || '';
        this.role = user.role || '';
        this.company = user.company || '';
        this.customer = user.customer || '';
        this.birthDate = user.birthDate ? new Date(user.birthDate) : null;
        this.observation = user.observation || '';
        this.lastConfirmationMail = user.lastConfirmationMail || '';
        this.mileageAccepted = user.mileageAccepted || '';
        this.mileageAcceptanceDate = user.mileageAcceptanceDate || '';
        this.dependents = user.dependents || [];
        this.avatar = user.avatar || '';
        this.userStatus = user.userStatus || '';
    }
}