import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';

import { AuthenticationService } from 'app/auth/authentication.service';

import { environment } from 'app/environment/environment';

import { Tower } from 'app/main/model/development/tower.model';
import { Development } from 'app/main/configurations/development/development.model';
import { AnyDocument } from 'app/main/model/any-document.model';

@Injectable()
export class TowersService implements Resolve<any>
{
    apiUrl = environment.apiUrl + "/tower";

    towers: any[];
    onTowersChanged: BehaviorSubject<any>;

    constructor(
        private _httpClient: HttpClient,
        private _authService: AuthenticationService
    )
    {
        this.onTowersChanged = new BehaviorSubject({});
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any
    {
        return new Promise((resolve, reject) => {

            Promise.all([
                this.getTowers()
            ]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }

    getTowers(development?: Development): Promise<any> {
        if(!development) {
            development = this._authService.currentDevelopmentValue;
        }

        let params = {
            development: development.id
        };

        return new Promise((resolve, reject) => {
            this._httpClient.get(this.apiUrl + "/list", {params: params}).subscribe((response: any) => {
                this.towers = response;
                this.onTowersChanged.next(this.towers);
                resolve(response);
            }, reject);
        });
    }

    findTower(id): Promise<Tower> {
        return new Promise((resolve) => {
            this._httpClient.get(this.apiUrl + "/find/" + id).subscribe((response) => {
                resolve(response as Tower);
            });
        });
    }

    createTower(tower: Tower): Promise<any> {
        let request = {
            description: tower.description,
            totalArea: tower.totalArea,
            module: tower.module,
            plan: tower.plan,
            towerDetails: tower.towerDetails,
            development: this._authService.currentDevelopmentValue
        };

        return new Promise((resolve, reject) => {
            this._httpClient.post(this.apiUrl + "/create", {...request}).subscribe((response: any) => {
                this.getTowers();
                resolve(response);
            }, reject);
        });
    }

    updateTower(tower, planDoc?: AnyDocument): Promise<any> {
        if(planDoc) {
            tower.plan = planDoc;
        }

        return new Promise((resolve, reject) => {
            this._httpClient.put(this.apiUrl + '/update', {...tower}).subscribe(response => {
                this.getTowers();
                resolve(response);
            });
        });
    }

    deleteTower(tower): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.put(this.apiUrl + "/delete", {...tower}).subscribe((response: any) => {
                this.getTowers();
                resolve(response);
            }, reject);
        });
    }
}
