import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

import { FuseUtils } from '@fuse/utils';

import { AuthenticationService } from 'app/auth/authentication.service';

import { Post } from 'app/main/model/post.model';
import { PostComment } from 'app/main/model/post-comment.model';

import { environment } from 'app/environment/environment';

@Injectable()
export class PostService
{
    apiUrl = environment.apiUrl + "/post";
    
    onPostsChanged: BehaviorSubject<any>;

    posts: Post[];

    constructor(
        private _httpClient: HttpClient,
        private _authService: AuthenticationService
    )
    {
        this.onPostsChanged = new BehaviorSubject([]);
    }

    getPosts(page): Promise<any> {
        let development = this._authService.currentDevelopmentValue;

        let request = {
            page: String(page)
        };

        return new Promise((resolve, reject) => {
                this._httpClient.get(this.apiUrl + '/list/' + development.id, {params: request}).subscribe((response: any) => {
                    this.posts = response;

                    this.onPostsChanged.next(this.posts);
                    resolve(this.posts);
                }, reject);
            }
        );
    }

    findPost(postId): Promise<any> {
        return new Promise((resolve, reject) => {
                this._httpClient.get(this.apiUrl + '/find/' + postId).subscribe((response: any) => {
                    resolve(response);
                }, reject);
            }
        );
    }

    createPost(post: Post): Promise<any> {
        let development = this._authService.currentDevelopmentValue;
        let user = this._authService.currentUserValue;

        let request = {
            title: post.title,
            message: post.message,
            images: post.images,
            development: development,
            allDevelopments: post.allDevelopments,
            user: user
        };

        return new Promise((resolve, reject) => {
            this._httpClient.post(this.apiUrl + '/create', request).subscribe((response: any) => {
                resolve(response);
            }, reject);
        });
    }

    createPostComment(comment: PostComment) {
        let user = this._authService.currentUserValue;
        let _post = new Post(comment.post);
        _post.postComments = null;

        let request = {
            post: _post,
            message: comment.message,
            postComment: comment.postComment,
            user: user
        };

        return new Promise((resolve, reject) => {
            this._httpClient.post(this.apiUrl + '/comment/create', request).subscribe((response: any) => {
                resolve(response);
            }, reject);
        });
    }

    updatePost(post: Post): Promise<any> {
        let user = this._authService.currentUserValue;

        let request = {
            id: post.id,
            title: post.title,
            message: post.message,
            images: post.images,
            allDevelopments: post.allDevelopments,
            user: user
        };

        return new Promise((resolve, reject) => {
            this._httpClient.put(this.apiUrl + '/update', request).subscribe((response: any) => {
                resolve(response);
            }, reject);
        });
    }

    deletePost(post: Post): Promise<any> {
        let user = this._authService.currentUserValue;

        let request = {
            id: post.id,
            user: user
        };

        return new Promise((resolve, reject) => {
            this._httpClient.put(this.apiUrl + '/delete', request).subscribe((response: any) => {
                resolve(response);
            }, reject);
        });
    }

    deletePostComment(postComment: PostComment): Promise<any> {
        let user = this._authService.currentUserValue;

        let request = {
            id: postComment.id,
            user: user
        };

        return new Promise((resolve, reject) => {
            this._httpClient.put(this.apiUrl + '/comment/delete', request).subscribe((response: any) => {
                resolve(response);
            }, reject);
        });
    }
}
