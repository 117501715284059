import { Role } from 'app/main/configurations/roles/role.model';
import { Development } from 'app/main/configurations/development/development.model';

export class RoleDevelopment
{
    role: Role;
    development: Development;

    /**
     * Constructor
     *
     * @param roleDevelopment
     */
    constructor(roleDevelopment?) {
        roleDevelopment = roleDevelopment || {};
        this.role = roleDevelopment.role || {};
        this.development = roleDevelopment.development || {};
    }

}