import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
    selector   : 'ume-dialog',
    templateUrl: './ume-dialog.component.html'
})
export class UmeDialogComponent
{
    public confirmMessage: string;
    public confirmButton: string = 'GENERAL.CONFIRM';
    public cancelButton: string = 'GENERAL.CANCEL';

    constructor(
        public dialogRef: MatDialogRef<UmeDialogComponent>
    ) {}

}
