import { Directive, ElementRef, Renderer2, HostListener, forwardRef, Injector, OnDestroy } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor, NgControl } from '@angular/forms';

@Directive({
    selector: '[dynamicPhoneMask]',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DynamicPhoneMaskDirective),
            multi: true
        }
    ]
})
export class DynamicPhoneMaskDirective implements ControlValueAccessor {

    private onChange: (value: string) => void;
    private onTouched: () => void;
    private lastValue: string = '';

    constructor(private el: ElementRef, private renderer: Renderer2) {

    }

    writeValue(value: any): void {
        if (value === undefined || value === null) {
            value = '';
        }
        this.lastValue = this.applyMask(value, false);
        this.renderer.setProperty(this.el.nativeElement, 'value', this.lastValue);
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        this.renderer.setProperty(this.el.nativeElement, 'disabled', isDisabled);
    }

    @HostListener('input', ['$event.target.value'])
    onInput(value: string): void {
        let formattedValue = this.applyMask(value, true);
        if (formattedValue !== this.lastValue) {
            this.lastValue = formattedValue;
            this.renderer.setProperty(this.el.nativeElement, 'value', formattedValue);
            this.onChange(formattedValue);
        }
    }

    @HostListener('blur')
    onBlur(): void {
        this.onTouched();
    }

    private applyMask(value: string, isUserInput: boolean): string {
        if (!isUserInput && !value.startsWith('+')) {
            value = '+55 ' + value;
        }

        if (isUserInput) {
            if (!value.startsWith('+')) {
                value = '+' + value; 
            } else if (value.startsWith('+55') && value.length === 3) {
                value += ' '; 
            }
        }

        return value.startsWith('+55 ') ? this.formatBrazilianNumber(value) : value;
    }

    private formatBrazilianNumber(value: string): string {
        let digits = value.replace(/[^\d]/g, '');
        if (digits.startsWith('55')) {
            digits = digits.substring(2); 
        }

        let formatted = '+55 ';

        if (digits.length > 0) {
            formatted += '(';
        }

        if (digits.length >= 2) {
            formatted += digits.substring(0, 2) + ') ';
        } else {
            formatted += digits;
            return formatted; 
        }

        let remainingDigits = digits.substring(2);

        if (remainingDigits.length == 8) {
            formatted += remainingDigits.substring(0, 4) + '-' + remainingDigits.substring(4);
        } else if (remainingDigits.length >= 9) {
            formatted += remainingDigits.substring(0, 1) + ' ' + remainingDigits.substring(1, 5) + '-' + remainingDigits.substring(5);
        } else {
            formatted += remainingDigits;
        }

        return formatted;
    }
}
