import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DynamicPhoneMaskDirective } from './dynamic-phone-mask.directive';

@NgModule({
  declarations: [DynamicPhoneMaskDirective],
  imports: [CommonModule],
  exports: [DynamicPhoneMaskDirective] 
})
export class DynamicPhoneMaskModule { }

