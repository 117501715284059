import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';

import { AuthenticationService } from 'app/auth/authentication.service';

import { environment } from 'app/environment/environment';

import { IntegCustomer } from 'app/main/model/integ/integ-customer.model';

@Injectable()
export class IntegCustomerService
{
    apiUrl = environment.apiUrl + "/integ/customer";
    
    integCustomer: IntegCustomer;
    onIntegCustomerChanged: BehaviorSubject<any>;

    integCustomers: IntegCustomer[];
    onIntegCustomersChanged: BehaviorSubject<any>;

    constructor(
        private _httpClient: HttpClient,
        private _authActivity: AuthenticationService
    )
    {
        this.onIntegCustomerChanged = new BehaviorSubject({});
        this.onIntegCustomersChanged = new BehaviorSubject([]);
    }

    find(integCustomerId): Promise<IntegCustomer> {
        return new Promise((resolve) => {
            this._httpClient.get(this.apiUrl + '/find/' + integCustomerId).subscribe((response: IntegCustomer) => {
                this.integCustomer = response;
                this.onIntegCustomerChanged.next(this.integCustomer);
                resolve(this.integCustomer);
            });
        });
    }

    list(userId): Promise<any[]> {
        let request = {
            fillImages: 'true'
        };

        return new Promise((resolve) => {
            this._httpClient.get(this.apiUrl + '/list/' + userId, {params: request}).subscribe((response: any[]) => {
                this.integCustomers = response;
                this.onIntegCustomersChanged.next(this.integCustomers);
                resolve(this.integCustomers);
            });
        });
    }
}