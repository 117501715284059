import { FuseUtils } from '@fuse/utils';

import { Development } from 'app/main/configurations/development/development.model';
import { User } from 'app/main/configurations/user/user.model';
import { AnyDocument } from 'app/main/model/any-document.model';
import { PostComment } from 'app/main/model/post-comment.model';

export class Post
{
    id: string;
    title: string;
    message: string;
    images: AnyDocument[];
    development: Development;
    allDevelopments: boolean;
    postComments: PostComment[];
    createdBy: User;
    creationDate: Date;

    selectedImage: number;

    /**
     * Constructor
     *
     * @param post
     */
    constructor(post?)
    {
        post = post || {};
        this.id = post.id || '';
        this.title = post.title || '';
        this.message = post.message || '';
        this.images = post.images || [];
        this.development = post.development || '';
        this.allDevelopments = post.allDevelopments || '';
        this.postComments = post.postComments || [];
        this.createdBy = post.createdBy || '';
        this.creationDate = post.creationDate || '';

        this.selectedImage = 1;
    }
}