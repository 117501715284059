import { Module } from 'app/main/development/modules/module.model';
import { Unity } from 'app/main/development/unities/unity.model';
import { Development } from 'app/main/configurations/development/development.model';
import { AnyDocument } from 'app/main/model/any-document.model';
import { SalesDetail } from './sales-detail.model';

export class Tower {
    id: string;
    description: string;
    totalArea: string;
    module: Module;
    plan: AnyDocument;
    unities: Unity[];
    development: Development;
    towerDetails: SalesDetail[];
    floorsCount: number;
    unitiesCount: number;
    expanded: boolean = false;

    constructor(tower?)
    {
        tower = tower || {};
        this.id = tower.id || '';
        this.description = tower.description || '';
        this.totalArea = tower.totalArea || '';
        this.module = tower.module || '';
        this.plan = tower.plan || '';
        this.unities = tower.unities || '';
        this.development = tower.development || '';
        this.towerDetails = tower.towerDetails || [];
        this.floorsCount = tower.floorsCount || '';
        this.unitiesCount = tower.unitiesCount || '';
    }

}