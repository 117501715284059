import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';

import { environment } from 'app/environment/environment';
import { AuthenticationService } from 'app/auth/authentication.service';

@Injectable()
export class CustomerEntryMethodService
{
    apiUrl = environment.apiUrl + "/lead/entryMethod";
    onCustomerEntryMethodsChanged: BehaviorSubject<any>;

    constructor(
        private _httpClient: HttpClient,
        private _authService: AuthenticationService
    )
    {
        this.onCustomerEntryMethodsChanged = new BehaviorSubject({});
    }

    list(): Promise<any> {
        return new Promise((resolve, reject) => {this._httpClient.get(this.apiUrl + "/list")
            .subscribe((response: any) => {
                this.onCustomerEntryMethodsChanged.next(response);
                resolve(response);
            }, reject);
        });
    }
}