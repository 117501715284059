
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';

import { AuthenticationService } from 'app/auth/authentication.service';

import { environment } from 'app/environment/environment';

@Injectable()
export class EmailTypeService
{
    apiUrl = environment.apiUrl + "/email/type";

    emailTypes: any[];
    onEmailTypesChanged: BehaviorSubject<any>;

    constructor(
        private _httpClient: HttpClient,
        private _authService: AuthenticationService
    )
    {
        this.onEmailTypesChanged = new BehaviorSubject({});
    }

    list(): Promise<any[]> {
        let request: any = {
        };

        return new Promise((resolve) => {
            this._httpClient.post(this.apiUrl + "/list", {...request}).subscribe((response: any[]) => {
                this.emailTypes = response;
                this.onEmailTypesChanged.next(this.emailTypes);
                resolve(this.emailTypes);
            });
        });
    }

    listByEmailTypeAndDevelopment(emailTypeKey, development?): Promise<any[]> {
        let developmentRequest;

        if (!development) {
            developmentRequest = this._authService.currentDevelopmentValue;
        } else {
            developmentRequest = development;
        }
     
        let request: any = {
            development: developmentRequest,
            emailTypeKey: emailTypeKey
        };
        console.log(request);
        return new Promise((resolve) => {
            this._httpClient.post(this.apiUrl + "/development/list", {...request}).subscribe((response: any[]) => {
                resolve(response);
            });
        });
    }

    create(emailTypeKey, email, development?): Promise<any> {
        let developmentRequest;

        if (!development) {
            developmentRequest = this._authService.currentDevelopmentValue;
        } else {
            developmentRequest = development;
        }

        let request = {
            development: developmentRequest,
            emailTypeKey: emailTypeKey,
            email: email
        };
        console.log(request);
        return new Promise((resolve) => {
            this._httpClient.post(this.apiUrl + "/development/create", {...request}).subscribe((response) => {
                resolve(response);
            });
        });
    }
    
    delete(emailType): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.put(this.apiUrl + "/development/delete", {...emailType})
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }
}