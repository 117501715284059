import { AnyDocument } from 'app/main/model/any-document.model';
import { BankAccount } from 'app/main/model/bank-account.model';
import { BankAssignor } from 'app/main/model/bank-assignor.model';
import { DevelopmentType } from 'app/main/model/development-type.model';
import { IntegCustomer } from 'app/main/model/integ/integ-customer.model';

export class Development
{
    id: string;
    name: string;
    fantasyName: string;
    phoneNumber: string;
    postalCode: string;
    street: string;
    number: string;
    complement: string;
    city: string;
    state: string;
    implantation: AnyDocument;
    logo: AnyDocument;
    cover: AnyDocument;
    primaryIdentification: string;
    secondaryIdentification: string;
    expirationDays: number;
    maximumDiscount: number;
    maximumOfferDiscount: number;
    maximumInstallmentDiscount: number;
    finePercentage: number;
    interestPercentage: number;
    fineMoneyValue: string;
    interestMoneyValue: string;
    deliveryDate: Date;
    developmentType: DevelopmentType;
    developmentFinancingIndexerType: any;
    participation: number;
    commissionStartDate: Date;
    unitiesCount: number;
    integCustomer: IntegCustomer;
    defaultBankAccount: BankAccount;
    defaultBankAssignor: BankAssignor;
    defaultExpirationDays: number;
    defaultExpiredAction: string;

    constructor(development?)
    {
        development = development || {};
        this.id = development.id || '';
        this.name = development.name || '';
        this.fantasyName = development.fantasyName || '';
        this.phoneNumber = development.phoneNumber || '';
        this.postalCode = development.postalCode || '';
        this.street = development.street || '';
        this.number = development.number || '';
        this.complement = development.complement || '';
        this.city = development.city || '';
        this.state = development.state || '';
        this.implantation = development.implantation || '';
        this.logo = development.logo || '';
        this.cover = development.cover || '';
        this.primaryIdentification = development.primaryIdentification || '';
        this.secondaryIdentification = development.secondaryIdentification || '';
        this.expirationDays = development.expirationDays || '';
        this.maximumDiscount = development.maximumDiscount || '';
        this.maximumOfferDiscount = development.maximumOfferDiscount || '';
        this.maximumInstallmentDiscount = development.maximumInstallmentDiscount || '';
        this.finePercentage = development.finePercentage || '';
        this.interestPercentage = development.interestPercentage || '';
        this.fineMoneyValue = development.fineMoneyValue || '';
        this.interestMoneyValue = development.interestMoneyValue || '';
        this.deliveryDate = development.deliveryDate || '';
        this.developmentType = development.developmentType || '';
        this.developmentFinancingIndexerType = development.developmentFinancingIndexerType || '';
        this.participation = development.participation || '';
        this.commissionStartDate = development.commissionStartDate ? new Date(development.commissionStartDate) : null;
        this.unitiesCount = development.unitiesCount;
        this.integCustomer = development.integCustomer;
        this.defaultBankAccount = development.defaultBankAccount;
        this.defaultBankAssignor = development.defaultBankAssignor;
        this.defaultExpirationDays = development.defaultExpirationDays;
        this.defaultExpiredAction = development.defaultExpiredAction;
    }
}