import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import { BehaviorSubject, Observable } from 'rxjs';

import { environment } from 'app/environment/environment';

import { Development } from 'app/main/configurations/development/development.model';
import { RoleDevelopment } from 'app/main/model/role-development.model';
import { CustomerDevelopment } from 'app/main/model/customer-development.model';
import { SupplierDevelopment } from 'app/main/model/supplier-development.model';
import { EmployeeDevelopment } from 'app/main/model/human-resources/employee-development.model';

@Injectable()
export class DevelopmentsService implements Resolve<any>
{
    apiUrl = environment.apiUrl + "/development";

    developments: any[];
    onDevelopmentsChanged: BehaviorSubject<any>;

    developmentsInRole: Development[];
    developmensNotInRole: Development[];

    developmentsInCustomer: Development[];
    developmentsNotInCustomer: Development[];

    developmentsInSupplier: Development[];
    developmentsNotInSupplier: Development[];

    developmentsInEmployee: Development[];
    developmentsNotInEmployee: Development[];

    developmentType: string;

    constructor(
        private _httpClient: HttpClient,
        private _sanitizer: DomSanitizer
    )
    {
        // Set the defaults
        this.onDevelopmentsChanged = new BehaviorSubject({});
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any
    {
        let routePath = route.routeConfig.path;
        this.developmentType = routePath == 'developmentCompanies' ? 'COMPANY' : routePath == 'developments' ? 'DEVELOPMENT' : null;

        return new Promise((resolve, reject) => {

            Promise.all([
                this.getDevelopments()
            ]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }

    getDevelopmentsByType(developmentType: string, fillImages: boolean = true): Promise<any> {
        let params = {
            developmentType: developmentType,
            fillImages: fillImages.toString()
        };

        return new Promise((resolve, reject) => {
            this._httpClient.get(this.apiUrl + "/list", {params: params}).subscribe((response: any) => {
                this.developments = this.sanitizeImages(response);
                this.onDevelopmentsChanged.next(this.developments);
                resolve(response);
            }, reject);
        });
    }

    getDevelopments(fillImages: boolean = true): Promise<any> {
        let params = {
            developmentType: this.developmentType ? this.developmentType : '',
            fillImages: fillImages.toString()
        };

        return new Promise((resolve, reject) => {
            this._httpClient.get(this.apiUrl + "/list", {params: params}).subscribe((response: any) => {
                this.developments = this.sanitizeImages(response);
                this.onDevelopmentsChanged.next(this.developments);
                resolve(response);
            }, reject);
        });
    }

    sanitizeImages(developments) {
        for(let i = 0; i == developments.length; i++) {
            if(developments[i]) {
                if(developments[i].implantation) {
                    developments[i].implantation = this._sanitizer.bypassSecurityTrustResourceUrl(developments[i].implantation);
                }

                if(developments[i].logo) {
                    developments[i].logo = this._sanitizer.bypassSecurityTrustResourceUrl(developments[i].logo);
                }
            }
        }

        return developments;
    }

    orderDevelopments(developments: Development[]): Promise<any> {
        let request = {
            developments: developments
        };

        return new Promise((resolve, reject) => {
            this._httpClient.put(this.apiUrl + '/order', request).subscribe(response => {
                this.getDevelopments();
                resolve(response);
            });
        });
    }

    getDevelopmentsNotInRole(roleId): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get(this.apiUrl + '/listNotInRole/' + roleId).subscribe((response: any) => {
                this.developmensNotInRole = response;

                this.developmensNotInRole = this.developmensNotInRole.map(development => {
                    return new Development(development);
                });

                resolve(this.developmensNotInRole);
            }, reject);
        });
    }

    getDevelopmentsInRole(roleId): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get(this.apiUrl + '/listInRole/' + roleId).subscribe((response: any) => {
                this.developmentsInRole = response;

                this.developmentsInRole = this.developmentsInRole.map(development => {
                    return new Development(development);
                });

                resolve(this.developmentsInRole);
            }, reject);
        });
    }

    createRoleDevelopment(role, development): Promise<any> {
        let roleDevelopment = new RoleDevelopment();
        roleDevelopment.role = role;
        roleDevelopment.role.menus = null;
        roleDevelopment.development = development;

        return new Promise((resolve, reject) => {
            this._httpClient.post(this.apiUrl.replace('development', 'roleDevelopment') + '/create', roleDevelopment).subscribe((response: any) => {
                resolve(response);
            }, reject);
        });
    }

    deleteRoleDevelopment(role, development): Promise<any> {
        let roleDevelopment = new RoleDevelopment();
        roleDevelopment.role = role;
        roleDevelopment.development = development;

        return new Promise((resolve, reject) => {
            this._httpClient.post(this.apiUrl.replace('development', 'roleDevelopment') + '/delete', roleDevelopment).subscribe((response: any) => {
                resolve(response);
            }, reject);
        });
    }

    getDevelopmentsNotInCustomer(customerId): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get(this.apiUrl + '/listNotInCustomer/' + customerId).subscribe((response: any) => {
                this.developmentsNotInCustomer = response;

                this.developmentsNotInCustomer = this.developmentsNotInCustomer.map(development => {
                    return new Development(development);
                });

                resolve(this.developmentsNotInCustomer);
            }, reject);
        });
    }

    getDevelopmentsInCustomer(customerId): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get(this.apiUrl + '/listInCustomer/' + customerId).subscribe((response: any) => {
                this.developmentsInCustomer = response;

                this.developmentsInCustomer = this.developmentsInCustomer.map(development => {
                    return new Development(development);
                });

                resolve(this.developmentsInCustomer);
            }, reject);
        });
    }

    createCustomerDevelopment(customer, development): Promise<any> {
        let customerDevelopment = new CustomerDevelopment();
        customerDevelopment.customer = customer;
        customerDevelopment.development = development;

        return new Promise((resolve, reject) => {
            this._httpClient.post(this.apiUrl.replace('development', 'customerDevelopment') + '/create', customerDevelopment).subscribe((response: any) => {
                resolve(response);
            }, reject);
        });
    }

    deleteCustomerDevelopment(customer, development): Promise<any> {
        let customerDevelopment = new CustomerDevelopment();
        customerDevelopment.customer = customer;
        customerDevelopment.development = development;

        return new Promise((resolve, reject) => {
            this._httpClient.post(this.apiUrl.replace('development', 'customerDevelopment') + '/delete', customerDevelopment).subscribe((response: any) => {
                resolve(response);
            }, reject);
        });
    }

    getDevelopmentsNotInEmployee(employeeId): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get(this.apiUrl + '/listNotInEmployee/' + employeeId).subscribe((response: any) => {
                this.developmentsNotInEmployee = response;

                this.developmentsNotInEmployee = this.developmentsNotInEmployee.map(development => {
                    return new Development(development);
                });

                resolve(this.developmentsNotInEmployee);
            }, reject);
        });
    }

    getDevelopmentsInEmployee(employeeId): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get(this.apiUrl + '/listInEmployee/' + employeeId).subscribe((response: any) => {
                this.developmentsInEmployee = response;

                this.developmentsInEmployee = this.developmentsInEmployee.map(development => {
                    return new Development(development);
                });

                resolve(this.developmentsInEmployee);
            }, reject);
        });
    }

    createEmployeeDevelopment(employee, development): Promise<any> {
        let employeeDevelopment = new EmployeeDevelopment();
        employeeDevelopment.employee = employee;
        employeeDevelopment.development = development;

        return new Promise((resolve, reject) => {
            this._httpClient.post(this.apiUrl.replace('development', 'employee') + '/development' + '/create', employeeDevelopment).subscribe((response: any) => {
                resolve(response);
            }, reject);
        });
    }

    deleteEmployeeDevelopment(employee, development): Promise<any> {
        let employeeDevelopment = new EmployeeDevelopment();
        employeeDevelopment.employee = employee;
        employeeDevelopment.development = development;

        return new Promise((resolve, reject) => {
            this._httpClient.post(this.apiUrl.replace('development', 'employee') + '/development'  + '/delete', employeeDevelopment).subscribe((response: any) => {
                resolve(response);
            }, reject);
        });
    }

    getDevelopmentsNotInSupplier(supplierId): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get(this.apiUrl + '/listNotInSupplier/' + supplierId).subscribe((response: any) => {
                this.developmentsNotInSupplier = response;

                this.developmentsNotInSupplier = this.developmentsNotInSupplier.map(development => {
                    return new Development(development);
                });

                resolve(this.developmentsNotInSupplier);
            }, reject);
        });
    }

    getDevelopmentsInSupplier(supplierId): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get(this.apiUrl + '/listInSupplier/' + supplierId).subscribe((response: any) => {
                this.developmentsInSupplier = response;

                this.developmentsInSupplier = this.developmentsInSupplier.map(development => {
                    return new Development(development);
                });

                resolve(this.developmentsInSupplier);
            }, reject);
        });
    }

    createSupplierDevelopment(supplier, development): Promise<any> {
        let supplierDevelopment = new SupplierDevelopment();
        supplierDevelopment.supplier = supplier;
        supplierDevelopment.development = development;

        return new Promise((resolve, reject) => {
            this._httpClient.post(this.apiUrl.replace('development', 'supplier') + '/development' + '/create', supplierDevelopment).subscribe((response: any) => {
                resolve(response);
            }, reject);
        });
    }

    deleteSupplierDevelopment(supplier, development): Promise<any> {
        let supplierDevelopment = new SupplierDevelopment();
        supplierDevelopment.supplier = supplier;
        supplierDevelopment.development = development;

        return new Promise((resolve, reject) => {
            this._httpClient.post(this.apiUrl.replace('development', 'supplier') + '/development'  + '/delete', supplierDevelopment).subscribe((response: any) => {
                resolve(response);
            }, reject);
        });
    }

    deleteDevelopment(development): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.put(this.apiUrl + "/delete", development).subscribe((response: any) => {
                this.getDevelopments();
                resolve(response);
            }, reject);
        });
    }

    updateDefaultBankAccount(development, bankAccount, bankAssignor, expirationDays, expiredAction): Promise<any> {
        let request ={
            development: development,
            bankAccount: bankAccount,
            bankAssignor: bankAssignor,
            expirationDays: expirationDays,
            expiredAction: expiredAction
        };

        return new Promise((resolve, reject) => {
            this._httpClient.put(this.apiUrl + "/update/bank/account", request).subscribe((response: any) => {
                resolve(response);
            }, reject);
        });
    }
}