import { Component, Inject, ViewEncapsulation, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatSnackBar, MatSnackBarHorizontalPosition,
    MatSnackBarVerticalPosition } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';

import { NgxMaskModule } from 'ngx-mask';

import { encode } from 'base64-arraybuffer';

import { PostService } from 'app/main/service/post.service';

import { Post } from 'app/main/model/post.model';
import { AnyDocument } from 'app/main/model/any-document.model';

@Component({
    selector     : 'post-form-dialog',
    templateUrl  : './post-form.component.html',
    styleUrls    : ['./post-form.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class PostFormDialogComponent {
    action: string;
    dialogTitle;

    horizontalPosition: MatSnackBarHorizontalPosition = 'center';
    verticalPosition: MatSnackBarVerticalPosition = 'top';

    post: Post;
    images: AnyDocument[] = [];
    displayedColumns: string[] = ['documentFileName'];

    postForm: FormGroup;

    constructor(
        public matDialogRef: MatDialogRef<PostFormDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: any,
        private _formBuilder: FormBuilder,
        private translate: TranslateService,
        private snackBar: MatSnackBar,
        private _postService: PostService
    )
    {
        this.action = _data.action;

        if ( this.action === 'new' ) {
            this.dialogTitle = this.translate.instant('POST.NEW');

            this.post = new Post();
        } else {
            this.dialogTitle = this.translate.instant('POST.EDIT');

            this.post = new Post(_data.post);
            this.images = this.post.images;
        }
        // Set the defaults
        this.postForm = this.createPostForm();
    }

    createPostForm(): FormGroup {
        return this._formBuilder.group({
            id           : [this.post.id],
            title        : [this.post.title],
            message      : [this.post.message],
            allDevelopments : [this.post.allDevelopments]
        });
    }

    onImagesChange(event) {
        if (event.target.files.length == 0) {
            console.log("No file selected!");
            return;
        } else {
            let validExtensions: boolean = true;
            for(let file of event.target.files) {
                let extension = file.name.match(/\.([^\.]+)$/)[1];
                let validExtension = false;

                switch(extension) {
                    case 'jpg':
                    case 'JPG':
                    case 'jpeg':
                    case 'JPEG':
                    case 'png':
                    case 'PNG':
                        validExtension = true;
                        break;
                }

                if(!validExtension) {
                    validExtensions = false;
                }
            }

            if(validExtensions) {
                let index = 0;
                for(let file of event.target.files) {
                    let imageDocument = new AnyDocument();

                    imageDocument.documentFileName = file.name;

                    const inputNode: any = document.querySelector('#images');

                    if (typeof (FileReader) !== 'undefined') {
                        const reader = new FileReader();

                        reader.onload = (e: any) => {
                            let arrayBufferResult = e.target.result;

                            imageDocument.documentFile = encode(arrayBufferResult);
                        };

                        reader.readAsArrayBuffer(inputNode.files[index]);
                    }

                    index++;
                    this.images.push(imageDocument);
                }
            } else {
                let message = this.translate.instant('MEDIA.INVALID-EXTENSION');
                this.snackBar.open(message, this.translate.instant('GENERAL.CLOSE'), {
                    duration: 5000,
                    horizontalPosition: this.horizontalPosition,
                    verticalPosition: this.verticalPosition,
                });
            }
        }
    }

    removeImage(index): void {
        this.images.splice(index, 1);
    }

    onSubmit(event) {
        this.post = this.postForm.getRawValue();

        if(!this.post.message && this.images.length <= 0) {
            const message = this.translate.instant('POST.NO-ATTACHMENT-NOR-MESSAGE');
            this.snackBar.open(message, this.translate.instant('GENERAL.CLOSE'), {
                duration: 5000,
                horizontalPosition: this.horizontalPosition,
                verticalPosition: this.verticalPosition,
            });
        } else {
            this.post.images = this.images;

            if(this.action == 'new') {
                this._postService.createPost(this.post).then((response) => {
                    this.matDialogRef.close(true);
                }).catch((error) => {
                    console.log(error);
                });
            } else {
                this._postService.updatePost(this.post).then((response) => {
                    this.matDialogRef.close(true);
                }).catch((error) => {
                    console.log(error);
                });
            }
        }
    }
}
