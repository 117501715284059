import { NgModule } from '@angular/core';

import { MatButtonModule, MatDialogModule } from '@angular/material';
import { FuseSharedModule } from '@fuse/shared.module';

import { TranslateModule } from '@ngx-translate/core';

import { UmeDialogComponent } from 'app/component/ume-dialog/ume-dialog.component';

@NgModule({
    declarations: [
        UmeDialogComponent
    ],
    imports: [
        FuseSharedModule,
        
        MatDialogModule,
        MatButtonModule,

        TranslateModule
    ],
    entryComponents: [
        UmeDialogComponent
    ],
})
export class UmeDialogModule
{
}
