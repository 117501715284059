import { FuseUtils } from '@fuse/utils';

import { Customer } from 'app/main/model/customer.model';
import { Development } from 'app/main/configurations/development/development.model';

export class CustomerDevelopment
{
    customer: Customer;
    development: Development;

    /**
     * Constructor
     *
     * @param customerDevelopment
     */
    constructor(customerDevelopment?) {
        customerDevelopment = customerDevelopment || {};
        this.customer = customerDevelopment.customer || {};
        this.development = customerDevelopment.development || {};
    }

}
