import { Component, Inject, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatButtonToggle, MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material';
import { FormBuilder, FormGroup } from '@angular/forms';

import { TranslateService } from '@ngx-translate/core';

import { MobileConfigService } from 'app/main/common/mobile-config-service';

@Component({
    selector   : 'share-dialog',
    templateUrl: './share-dialog.component.html',
    styleUrls    : ['./share-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ShareDialogComponent implements OnInit {
    horizontalPosition: MatSnackBarHorizontalPosition = 'center';
    verticalPosition: MatSnackBarVerticalPosition = 'top';

    public shareMessage: string;

    document: any;

    isDownload: boolean = false;
    isEmail: boolean = false;
    isWhatsApp: boolean = false;

    emailForm: FormGroup;

    @ViewChild('matButtonToggleGroup')
    matButtonToggleGroup: MatButtonToggle;

    constructor(
        public dialogRef: MatDialogRef<ShareDialogComponent>,
        private _mobileService: MobileConfigService,
        private _formBuilder: FormBuilder,
        @Inject(MAT_DIALOG_DATA) private _data: any,
        private _snackBar: MatSnackBar,
        private translate: TranslateService
    ) {}

    ngOnInit(): void {
        if(this._data.document) {
            this.document = this._data.document;
        } else {
            this.dialogRef.close(false);
        }

        this.matButtonToggleGroup.change.subscribe((response) => {
            this.isDownload = false;
            this.isEmail = false;
            this.isWhatsApp = false;
        
            if(response.value == 'download') {
                this.isDownload = true;
                this.downloadDocument();
            } else if(response.value == 'email') {
                this.isEmail = true;
            } else if(response.value == 'whatsapp') {
                this.isWhatsApp = true;
                this.whatsAppShare();
            }
        });

        this.emailForm = this.createEmailForm();
    }

    createEmailForm(): FormGroup {
        return this._formBuilder.group({
            email: ['']
        });
    }

    downloadDocument(): void {
        if(this.document) {
            window.open(this.document.url, "_blank");

            this.dialogRef.close(true);
        }
    }

    sendMail(): void {
        let email = this.emailForm.getRawValue().email;

        if(this.emailForm.valid && email) {
            this.dialogRef.close({ isEmail: true, email: email });
        } else {
            const message = this.translate.instant('SHARE.EMAIL-REQUIRED');

            this._snackBar.open(message, this.translate.instant('GENERAL.CLOSE'), {
                duration: 3000,
                horizontalPosition: this.horizontalPosition,
                verticalPosition: this.verticalPosition,
            });
        }
    }

    whatsAppShare(): void {
        if(this.document) {
            let url = "https://api.whatsapp.com/send?text=" + this.shareMessage;

            window.open(url, "_blank");

            this.dialogRef.close(true);
        }
    }

    cancel(): void {
        this.dialogRef.close(false);
    }
}
