import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material';
import * as _ from 'lodash';

import { navigation } from 'app/navigation/navigation';

import { FuseConfigService } from '@fuse/services/config.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';

import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

import { locale as english } from 'app/i18n/en';
import { locale as portuguese } from 'app/i18n/pt';

import { UmeDialogComponent } from 'app/component/ume-dialog/ume-dialog.component';

import { AuthenticationService } from 'app/auth/authentication.service';
import { UserNotificationService } from 'app/main/service/user-notification-service';
import { DevelopmentsService } from 'app/main/configurations/developments/developments.service';
import { IntegCustomerService } from 'app/main/service/integ/integ-customer.service';
import { UserIntegTermService } from 'app/main/service/integ/user-integ-term.service';

import { Development } from 'app/main/configurations/development/development.model';
import { IntegCustomer } from 'app/main/model/integ/integ-customer.model';

import { LeadFormDialogComponent } from 'app/main/lead/lead-form-dialog/lead-form-dialog.component';
import { IntegAcceptanceTermDialogComponent } from 'app/main/integ/term/integ-acceptance-term-dialog.component';

@Component({
    selector     : 'toolbar',
    templateUrl  : './toolbar.component.html',
    styleUrls    : ['./toolbar.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ToolbarComponent implements OnInit, OnDestroy {
    confirmDialogRef: MatDialogRef<UmeDialogComponent>;

    horizontalNavbar: boolean;
    rightNavbar: boolean;
    hiddenNavbar: boolean;
    languages: any;
    navigation: any;
    selectedLanguage: any;
    developments: any[] = [];
    companies: any[] = [];
    private selectedDevelopment: Development;
    currentUser;
    avatarUrl;
    selectedDevelopmentLogo: any; 
    notificationsCount = 0;
    configs: any[];
    hasCreateLeadAction: boolean = false;
    hasRealtorCreateLeadAction: boolean = true;
    integCustomers: IntegCustomer[];

    dialogRef: any;

    private _unsubscribeAll: Subject<any>;

    constructor(
        private _authService: AuthenticationService,
        private _integCustomerService: IntegCustomerService,
        private _userIntegTermService: UserIntegTermService,
        private _fuseConfigService: FuseConfigService,
        private _fuseSidebarService: FuseSidebarService,
        private translate: TranslateService,
        private router: Router,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _developmentsService: DevelopmentsService,
        private _notificationService: UserNotificationService,
        public _matDialog: MatDialog
    )
    {
        this._fuseTranslationLoaderService.loadTranslations(english, portuguese);
        // Set the defaults

        //LANGUAGE BAR
        this.languages = [
            {
                id   : 'pt',
                title: 'Português',
                flag : 'pt'
            },
            {
                id   : 'en',
                title: 'English',
                flag : 'us'
            }
            
        ];

        this.navigation = navigation;

        if(this._authService.currentUserValue && this._authService.currentIntegCustomerValue) {
            this.listIntegCustomers();
        }

        this._authService.currentDevelopment.subscribe((development) => {
            this.selectedDevelopment = development; 
        });

        this._authService.currentDevelopmentLogo.subscribe((logo) => {
            this.selectedDevelopmentLogo = logo;
        });     
        
        this.currentUser = this._authService.currentUserValue;

        this._authService.currentAvatar.subscribe((avatar) => {
            if(avatar) {
                this.avatarUrl = 'data:image/jpg;base64,' + avatar.documentFile;
            } else {
                this.avatarUrl = 'assets/images/avatars/engineer.png';
            }
        });

        this._unsubscribeAll = new Subject();
    }

    ngOnInit(): void
    {
        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((settings) => {
                this.horizontalNavbar = settings.layout.navbar.position === 'top';
                this.rightNavbar = settings.layout.navbar.position === 'right';
                this.hiddenNavbar = settings.layout.navbar.hidden === true;
            });

        // Set the selected language from default languages
        this.selectedLanguage = _.find(this.languages, {'id': this.translate.currentLang});

        this.getNotificationsCount();
        this.loadConfigMenu();

        this._authService.userActionKeys.subscribe((response) => {
            this.loadConfigMenu();
        });
    }

    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    listIntegCustomers(): void {
        let user = this._authService.currentUserValue;

        this._integCustomerService.list(user.id).then((response) => {
            this.integCustomers = response;

            for(let integCustomer of this.integCustomers) {
                this._integCustomerService.find(integCustomer.id).then((response: any) => {
                    if(response && response.role) {
                        let role = response.role;
                        let developments: Development[] = [];
                        let companies: Development[] = [];
                        
                        this._developmentsService.getDevelopmentsInRole(role.id).then((response) => {
                            for(let development of response) {
                                if(development.developmentType.developmentTypeKey == 'DEVELOPMENT') {
                                    developments.push(development);
                                } else {
                                    companies.push(development);
                                }
                            }
                        });

                        integCustomer.developments = developments;
                        integCustomer.companies = companies;
                    }
                });
            }

            for(let i = 0; i < this.integCustomers.length; i++) {
                let integCustomer = this.integCustomers[i];
                if(this.selectedDevelopment && this.selectedDevelopment.integCustomer.id == integCustomer.id) {
                    this.integCustomers.splice(i, 1);
                    this.integCustomers.splice(0, 0, integCustomer);
                    break;
                }
            }
        });
    }

    toggleIntegCustomer(index: number): void {
        for(let i = 0; i < this.integCustomers.length; i++) {
            if(i != index) {
                this.integCustomers[i].expanded = false;
            } else {
                this.integCustomers[index].expanded = !this.integCustomers[index].expanded;
            }
        }
    }

    getNotificationsCount(): void {
        // Subscribe to update user on changes
        this._notificationService.onNotificationsChanged
            .subscribe(response => {
                if(response) {
                    this.notificationsCount = response.newNotificationsCount;
                }
            });


       let pageNumber = 0;  
       let isQuickPanel = true;

       this._notificationService.getNotifications(pageNumber, isQuickPanel)
            .then((response) => {
                if(response) {
                    this.notificationsCount = response.newNotificationsCount;
                }
            }); 
    }

    openIntegTerm(): void {
        this._userIntegTermService.find().then((mileageTerm) => {
            let _data = {
                integTermUrl: mileageTerm.integTermUrl,
                actionButtons: false
            }

            this.dialogRef = this._matDialog.open(IntegAcceptanceTermDialogComponent, {
                panelClass: 'integ-acceptance-term-dialog',
                data: _data,
                position: {
                    'top' : '100px'
                }
            });
        });
    }

    loadConfigMenu(): void {
        this.configs = [];
        let index = 0;

        this.hasCreateLeadAction = this._authService.hasAction('CREATE_LEAD');
        this.hasRealtorCreateLeadAction = this._authService.hasAction('REALTOR_CREATE_LEAD');

        if(this._authService.hasAction('USER_CONFIG_MENU')) {
            this.configs.splice(index, 0, {
                title: 'NAV.USER.TITLE',
                url: '/configurations/users',
                icon: 'verified_user'
            });
            index++;
        }

        if(this._authService.hasAction('ROLE_CONFIG_MENU')) {
            this.configs.splice(index, 0, {
                title: 'NAV.ROLE.TITLE',
                url: '/configurations/roles',
                icon: 'account_box'
            });
            index++;
        }

        if(this._authService.hasAction('COMPANY_CONFIG_MENU')) {
            this.configs.splice(index, 0, {
                title: 'NAV.COMPANY.TITLE',
                url: '/configurations/companies',
                icon: 'account_balanced'
            });
            index++;
        }

        if(this._authService.hasAction('SUPPLIER_CONFIG_MENU')) {
            this.configs.splice(index, 0, {
                title: 'NAV.SUPPLIER.TITLE',
                url: '/configurations/suppliers',
                icon: 'supervisor_account'
            });
            index++;
        }

        if(this._authService.hasAction('DEVELOPMENT_CONFIG_MENU')) {
            this.configs.splice(index, 0, {
                title: 'NAV.DEVELOPMENT.TITLE',
                url: '/configurations/developments',
                icon: 'business'
            });
            index++;
        }

        if(this._authService.hasAction('DEVELOPMENT_COMPANY_CONFIG_MENU')) {
            this.configs.splice(index, 0, {
                title: 'NAV.DEVELOPMENT-COMPANY.TITLE',
                url: '/configurations/developmentCompanies',
                icon: 'location_city'
            });
            index++;
        }
    }

    openLeadDialog(realtor: boolean): void {
        let _data = {
            action : "new",
            realtor: realtor
        };

        this.dialogRef = this._matDialog.open(LeadFormDialogComponent, {
            panelClass: 'lead-form-dialog',
            data: _data
        });

        this.dialogRef.afterClosed().subscribe((response) => {
            if ( !response ) {
                return;
            }
        });
    }


    selectMenu(url, blank: boolean = false): void {
        if(blank) {
            window.open(url, "_blank");
        } else {
            this.router.navigate([url]);
        }
    }

    toggleSidebarOpen(key): void
    {
        this._fuseSidebarService.getSidebar(key).toggleOpen();
    }

    setLanguage(lang): void
    {
        // Set the selected language for the toolbar
        this.selectedLanguage = lang;

        // Use the selected language for translations
        this.translate.use(lang.id);
    }

    selectDevelopment(development: Development) {
        let integCustomer = this._authService.currentIntegCustomerValue;
        if(development.integCustomer.id != integCustomer.id) {
            this._authService.selectIntegCustomer(development.integCustomer).then((response) => {
                this.loadDevelopment(development);
            });
        } else {
            this.loadDevelopment(development);
        }
    }

    loadDevelopment(development: Development): void {
        if(development.id != this.selectedDevelopment.id) {
            this._authService.selectDevelopment(development).then((response) => {
                this.router.navigate(['/home']);
            });
        }
    }

    openNotificationList() {
         this.router.navigate(['/user/notification/list']);
    }

    deactivateAccount() {
        this.confirmDialogRef = this._matDialog.open(UmeDialogComponent, {
            disableClose: false
        });

        this.confirmDialogRef.componentInstance.confirmMessage = this.translate.instant('Deseja excluir definitivamente sua conta ? Você não poderá mais acessar a plataforma.');

        this.confirmDialogRef.afterClosed().subscribe(result => {
            if ( result ) {
                this._authService.deactivateAccount().then((response) => {
                    this.logout();
                });
            }
            this.confirmDialogRef = null;
        });
    }

    logout(): void {
        this._authService.logout();
        
        let route = this._authService.getLogoutRoute();

        this.router.navigate([route]);
    }
}
