import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';

import { Tower } from 'app/main/model/development/tower.model';

import { environment } from 'app/environment/environment';

@Injectable()
export class SalesTowerService implements Resolve<any>
{
    apiUrl = environment.apiUrl;

    routeParams: any;
    tower: any;
    onTowerChanged: BehaviorSubject<any>;

    floors: any[];
    onFloorsChanged: BehaviorSubject<any>;

    constructor(
        private _httpClient: HttpClient
    )
    {
        // Set the defaults
        this.onTowerChanged = new BehaviorSubject({});
        this.onFloorsChanged = new BehaviorSubject({});
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any
    {
        this.routeParams = route.params;
        this.tower = new Tower();
        this.tower.id = this.routeParams.id;

        return new Promise((resolve, reject) => {

            Promise.all([
                this.getTower(this.tower)
            ]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }

    getTower(tower): Promise<any> {
        return new Promise((resolve, reject) => {
                this._httpClient.get(this.apiUrl + '/tower/find/' + tower.id)
                    .subscribe((response: any) => {
                        this.tower = response;
                        this.onTowerChanged.next(this.tower);
                        resolve(this.tower);
                    }, reject);
            }
        );
    }

    getFloors(tower): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get(this.apiUrl + "/sales/floor/list/"+tower.id)
                .subscribe((response: any) => {
                    this.floors = response;
                    this.onFloorsChanged.next(this.floors);
                    resolve(response);
                }, reject);
        });
    }

}
