import { Component, Inject, ViewEncapsulation, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatSnackBar, MatSnackBarHorizontalPosition,
    MatSnackBarVerticalPosition } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';

import { validate as validateCpf } from 'gerador-validador-cpf';

import { LeadService } from 'app/main/lead/lead.service';
import { TypologyService } from 'app/main/service/typology.service';
import { CustomerEntryMethodService } from 'app/main/service/customer-entry-method.service';
import { DevelopmentsService } from 'app/main/configurations/developments/developments.service';

@Component({
    selector     : 'lead-form-dialog',
    templateUrl  : './lead-form-dialog.component.html',
    styleUrls    : ['./lead-form-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class LeadFormDialogComponent {
    @ViewChild("primaryDocument")
    primaryDocument: ElementRef;

    action: string;
    additionalInformationTextPlaceholder: string;
    dialogTitle;

    horizontalPosition: MatSnackBarHorizontalPosition = 'center';
    verticalPosition: MatSnackBarVerticalPosition = 'top';

    lead: any;
    tipologies: any[];
    customerEntryMethods: any[];
    developments: any[];

    leadForm: FormGroup;

    constructor(
        private _leadService: LeadService,
        private _typologyService: TypologyService,
        private _developmentService: DevelopmentsService,
        private _customerEntryMethodService: CustomerEntryMethodService,
        public matDialogRef: MatDialogRef<LeadFormDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: any,
        private _formBuilder: FormBuilder,
        private translate: TranslateService,
        private snackBar: MatSnackBar
    )
    {
        this.action = _data.action;

        if(this.action == 'edit' && _data.lead) {
            this.lead = _data.lead;
            this.dialogTitle = this.translate.instant('LEAD.LEAD-FORM-UPDATE-DIALOG-TITLE');
        } else {
            this.dialogTitle = this.translate.instant('LEAD.LEAD-FORM-DIALOG-TITLE');
            this.lead = {};
        }

        this.leadForm = this.createLeadForm();
        this.loadTipologies();
        this.loadCustomerEntryMethods();
        this.loadDevelopments();
    }

    loadTipologies(): void {
        this._typologyService.list().then((response) => {
            this.tipologies = response;
        });
    }

    loadCustomerEntryMethods(): void {
        this._customerEntryMethodService.list().then((response) => {
            this.customerEntryMethods = response;
        });
    }

    loadDevelopments(): void {
        this._developmentService.getDevelopments(false).then((response) => {
            this.developments = response;
        });
    }

    createLeadForm(): FormGroup {
        return this._formBuilder.group({
            id: [this.lead.id],
            primaryDocument      : [this.lead.primaryDocument],
            name: [this.lead.name],
            mobileNumber: [this.lead.mobileNumber],
            email: [this.lead.email],
            customerEntryMethod: [this.lead.customerEntryMethod],
            interestedTypology: [this.lead.interestedTypology],
            interestedDevelopment: [this.lead.interestedDevelopment],
            estimatedValue: [this.lead.estimatedValue],
            additionalInformation      : [this.lead.additionalInformation]
        });
    }

    compareCustomerEntryMethod(c1, c2): boolean {
        return c1.id == c2.id;
    }
    
    compareInterestedTypology(t1, t2): boolean {
        return t1.id == t2.id;
    }
    
    compareInterestedDevelopment(d1, d2): boolean {
        return d1.id == d2.id;
    }

    onPrimaryDocumentChange(event): void {
        let primaryDocument = event.target.value;

        if(primaryDocument.length >= 14) {
            let valid = validateCpf(primaryDocument);

            if(valid) {
                // this._customerService.getCustomer(primaryDocument).then((response) => {
                //     if(response) {
                //         this.leadForm.patchValue({customerName: response.name});
                //     }
                // }).catch((error) => {
                //     console.log(error);
                // });
            } else {
                this.leadForm.controls['primaryDocument'].setErrors({'incorrect': true});
                
                this.leadForm.patchValue({primaryDocument: ''});
                this.primaryDocument.nativeElement.focus();

                let message = this.translate.instant('CUSTOMER.INVALID-PRIMARY-DOCUMENT');
                this.snackBar.open(message, this.translate.instant('GENERAL.CLOSE'), {
                    duration: 5000,
                    horizontalPosition: this.horizontalPosition,
                    verticalPosition: this.verticalPosition,
                });
            }
        } else {
            this.leadForm.controls['primaryDocument'].setErrors({'incorrect': true});
        }
    }

    onSubmit() {
        this.lead = this.leadForm.getRawValue();

        if(!this.lead.mobileNumber && !this.lead.email) {
            const message = this.translate.instant('LEAD.NO-PHONE-NOR-EMAIL');
            this.snackBar.open(message, this.translate.instant('GENERAL.CLOSE'), {
                duration: 5000,
                horizontalPosition: this.horizontalPosition,
                verticalPosition: this.verticalPosition,
            });
        } else {
            if(this.action == 'new') {
                this._leadService.createLead(this.lead, this._data.realtor).then((response) => {
                    const message = this.translate.instant('LEAD.INCLUDED');
                    this.snackBar.open(message, this.translate.instant('GENERAL.CLOSE'), {
                        duration: 5000,
                        horizontalPosition: this.horizontalPosition,
                        verticalPosition: this.verticalPosition,
                    });

                    this.matDialogRef.close(this.lead);
                });
            } else {
                this._leadService.updateLead(this.lead).then((response) => {
                    const message = this.translate.instant('LEAD.UPDATED');
                    this.snackBar.open(message, this.translate.instant('GENERAL.CLOSE'), {
                        duration: 5000,
                        horizontalPosition: this.horizontalPosition,
                        verticalPosition: this.verticalPosition,
                    });

                    this.matDialogRef.close(this.lead);
                });
            }
        }
    }
}
