import { NgModule } from '@angular/core';
import { OverlayModule } from '@angular/cdk/overlay';
import { SpinnerOverlayModule } from 'app/core/spinner-overlay/spinner-overlay.module';

@NgModule({
  imports: [
    OverlayModule,
    SpinnerOverlayModule
  ],
  declarations: []
})
export class CoreModule { }