import { Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';

import { fuseAnimations } from '@fuse/animations';

import { Subject } from 'rxjs';

import { GALLERY_CONF, NgxImageGalleryComponent } from "ngx-image-gallery";

import { UmeDialogComponent } from 'app/component/ume-dialog/ume-dialog.component';

import { AuthenticationService } from 'app/auth/authentication.service';
import { PostService } from 'app/main/service/post.service';

import { PostFormDialogComponent } from 'app/main/news/post-form/post-form.component';

import { Post } from 'app/main/model/post.model';

import { CommentTimelineComponent } from 'app/main/news/comment-timeline/comment-timeline.component';

@Component({
    selector     : 'news',
    templateUrl  : './news.component.html',
    styleUrls    : ['./news.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations   : fuseAnimations
})
export class NewsComponent implements OnInit, OnDestroy {
    // gallery configuration
    inlineConf: GALLERY_CONF = {
        imageOffset: '0px',
        inline: true,
        showDeleteControl: false,
        showImageTitle: false,
        showCloseControl: false
    };

    @ViewChild(NgxImageGalleryComponent)
    ngxImageGallery: NgxImageGalleryComponent;

    confirmDialogRef: MatDialogRef<UmeDialogComponent>;
    dialogRef: any;

    avatarUrl: any;
    imageUrl: any;
    hasAddPostAction: boolean;
    lightboxAlbum: any;

    hasViewCommentsAction: boolean = false;
    hasPostCommentDeleteAction: boolean = false;
    hasPostDeleteAction: boolean = false;
    hasPostUpdateAction: boolean = false;
    hasPostCommentReplyAction: boolean = false;
    currentUserId: any;

    selectedDevelopment: any;
    selectedDevelopmentLogo: any; 
    posts: Post[];

    currentPage: number = 0;
    pageTotal: number = 3;
    pageSize: number = 3;
    totalPosts: number = 14;

    // Private
    private _unsubscribeAll: Subject<any>;

    constructor(
        private _authService: AuthenticationService,
        public _matDialog: MatDialog,
        private _postService: PostService
    )
    {
        this._unsubscribeAll = new Subject();

        this.selectedDevelopment = this._authService.currentDevelopmentValue;

        this._authService.currentDevelopmentLogo.subscribe((logo) => {
            this.selectedDevelopmentLogo = logo;
        });
    }

    ngOnInit(): void {
        this._authService.currentDevelopment.subscribe((response) => {
            this.selectedDevelopment = response;
            this.loadInit();
        });
    }

    loadInit(): void {
        this.hasAddPostAction = this._authService.hasAction('ADD_POST');

        this._authService.currentAvatar.subscribe((avatar) => {
            if(avatar) {
                this.avatarUrl = 'data:image/jpg;base64,' + avatar.documentFile;
            } else {
                this.avatarUrl = 'assets/images/avatars/engineer.png';
            }
        });

        if(this._authService.currentUserValue) {
            this.loadActions();
            this.currentUserId = this._authService.currentUserValue.id;

            this.imageUrl = 'assets/images/demo-content/demo.jpg';
            this.listPosts();
        }
    }

    loadActions(): void {
        this.hasViewCommentsAction = this._authService.hasAction('VIEW_COMMENTS');
        this.hasAddPostAction = this._authService.hasAction('ADD_POST');
        this.hasPostUpdateAction = this._authService.hasAction('UPDATE_POST');
        this.hasPostDeleteAction = this._authService.hasAction('DELETE_POST');
        this.hasPostCommentDeleteAction = this._authService.hasAction('DELETE_POST_COMMENT');
        this.hasPostCommentReplyAction = this._authService.hasAction('REPLY_COMMENTS');
    }

    openGallery(post) {
        this.openTimeline(post);
    }

    canUpdatePost(post: Post): boolean {
        if(this.hasPostUpdateAction || post.createdBy.id == this.currentUserId) {
            return true;
        } else {
            return false;
        }
    }

    canDeletePost(post: Post): boolean {
        if(this.hasPostDeleteAction || post.createdBy.id == this.currentUserId) {
            return true;
        } else {
            return false;
        }
    }

    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    less(): void {
        this.currentPage--;
        this.updatePageTotal();

        this.listPosts();
    }

    more(): void {
        this.currentPage++;
        this.updatePageTotal();

        this.listPosts();
    }

    updatePageTotal(): void {
        this.pageTotal = (this.currentPage + 1) * this.pageSize;

        if(this.pageTotal > this.totalPosts) {
            this.pageTotal = this.totalPosts;
        }
    }

    listPosts(): void {
        this._postService.getPosts(this.currentPage).then((response) => {
            this.posts = response.posts;
            this.totalPosts = response.postsTotalCount;
        });
    }

    newPost(): void {
        let _data = {
            action: 'new'
        };

        this.dialogRef = this._matDialog.open(PostFormDialogComponent, {
            panelClass: 'post-form-dialog',
            data: _data
        });

        this.dialogRef.afterClosed()
            .subscribe((response) => {
                if ( !response ) {
                    return;
                } else {
                    this.listPosts();
                }
            });
    }

    editPost(post: Post) {
        let _data = {
            action: 'edit',
            post: post
        };

        this.dialogRef = this._matDialog.open(PostFormDialogComponent, {
            panelClass: 'post-form-dialog',
            data: _data
        });

        this.dialogRef.afterClosed()
            .subscribe((response) => {
                if ( !response ) {
                    return;
                } else {
                    this.listPosts();
                }
            });
    }

    deletePost(post: Post, index) {
        this.confirmDialogRef = this._matDialog.open(UmeDialogComponent, {
            disableClose: false
        });

        this.confirmDialogRef.afterClosed().subscribe(result => {
            if ( result )
            {
                this._postService.deletePost(post)
                    .then((response) => {
                        this.posts.splice(index, 1);
                    });
            }
            this.confirmDialogRef = null;
        });
    }

    openTimeline(post): void {
        console.log(post);
        let _data = {
            post: post
        };

        this.dialogRef = this._matDialog.open(CommentTimelineComponent, {
            panelClass: 'comment-timeline',
            data: _data
        });
                
    }
}
