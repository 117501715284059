import { NgModule, LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule, MatIconModule, MatSnackBarModule, MatProgressSpinnerModule, MatPaginatorIntl } from '@angular/material';

import { getPortuguesePaginatorIntl } from 'app/i18n/pt-br-paginator';

import { TranslateModule } from '@ngx-translate/core';

import 'hammerjs';

import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseProgressBarModule, FuseSidebarModule } from '@fuse/components';

import { fuseConfig } from 'app/fuse-config';

import { AppComponent } from 'app/app.component';
import { LayoutModule } from 'app/layout/layout.module';
import { HomeModule } from 'app/main/home/home.module';
import { CoreModule } from 'app/core/core.module';
import { SharedModule } from 'app/shared/shared.module';
import { Interceptor, HTTPStatus } from 'app/auth/interceptor.module';

import { APP_BASE_HREF, registerLocaleData } from '@angular/common';

import { SpinnerOverlayComponent } from 'app/core/spinner-overlay/spinner-overlay.component';

import { UserNotificationService } from 'app/main/service/user-notification-service';

import localePt from '@angular/common/locales/pt';

import { MomentDateAdapter} from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { TreeModule } from 'angular-tree-component';

export const LOCAL_FORMAT = {
    parse: {
        dateInput: 'DD/MM/YYYY'
    },
    display: {
        dateInput: 'DD/MM/YYYY',
        monthYearLabel: 'MMMM YYYY',
        dateA11yLabel: 'MM/DD/YYYY',
        monthYearA11yLabel: 'MMMM YYYY'
    }
};

registerLocaleData(localePt);

const RxJS_Services = [Interceptor, HTTPStatus];

const appRoutes: Routes = [
    {
        path        : 'auth',
        loadChildren: './main/auth/auth.module#AuthModule'
    },
    {
        path        : 'configurations',
        loadChildren: './main/configurations/configurations.module#ConfigurationsModule'
    },
    {
        path        : 'development',
        loadChildren: './main/development/development.module#DevelopmentModule'
    },
    {
        path        : 'sales',
        loadChildren: './main/sales/sales.module#SalesModule'
    },
    {
        path        : 'engineering',
        loadChildren: './main/engineering/engineering.module#EngineeringModule'
    },
    {
        path        : 'media',
        loadChildren: './main/media/media.module#MediaModule'
    },
    {
        path        : 'fidelity',
        loadChildren: './main/mileage/mileage.module#MileageModule'
    },
    {
        path        : 'customers',
        loadChildren: './main/customer/customer.module#CustomerModule'
    },
    {
        path        : 'user',
        loadChildren: './main/user/user.module#UserModule'
    },
    {
        path        : 'company',
        loadChildren: './main/company/company.module#CompanyModule'
    },
    {
        path        : 'admin',
        loadChildren: './main/admin/admin.module#AdminModule'
    },
    {
        path        : 'reports',
        loadChildren: './main/report/report.module#ReportModule'
    },
    {
        path        : 'financial',
        loadChildren: './main/financial/financial.module#FinancialModule'
    },
    {
        path        : 'human/resources',
        loadChildren: './main/human-resources/human-resources.module#HumanResourcesModule'
    },    
    {
        path        : 'leads',
        loadChildren: './main/lead/lead.module#LeadModule'
    },    
    {
        path        : 'planning',
        loadChildren: './main/planning/planning.module#PlanningModule'
    },
    {
        path        : 'process',
        loadChildren: './main/process/process.module#ProcessModule'
    },
    {
        path        : 'supply',
        loadChildren: './main/supply/supply.module#SupplyModule'
    },
    {
        path        : 'accounting',
        loadChildren: './main/accounting/accounting.module#AccountingModule'
    },
    {
        path        : 'post-construction',
        loadChildren: './main/post-construction/post-construction.module#PostConstructionModule'
    },
    {
        path      : '**',
        redirectTo: 'auth/login'
    }
];

@NgModule({
    declarations: [
        AppComponent,
    ],
    imports     : [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        Interceptor,
        // RouterModule.forRoot(appRoutes, { enableTracing: true }),
        RouterModule.forRoot(appRoutes),

        TranslateModule.forRoot(),

        // Material moment date module
        MatMomentDateModule,

        // Material
        MatButtonModule,
        MatIconModule,
        MatSnackBarModule,
        MatProgressSpinnerModule,

        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,

        // App modules
        LayoutModule,
        HomeModule,
        CoreModule,
        SharedModule,
        TreeModule.forRoot()
    ],
    bootstrap   : [
        AppComponent
    ],
    providers: [
        ...RxJS_Services,
        {
            provide: APP_BASE_HREF,
            useValue: '/'
        },
        {
            provide: LOCALE_ID,
            useValue: 'pt-BR'
        },
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE]
        },
        {
            provide: MAT_DATE_FORMATS,
            useValue: LOCAL_FORMAT
        },
        {
            provide: MatPaginatorIntl,
            useValue: getPortuguesePaginatorIntl()
        },
        UserNotificationService
    ],
    entryComponents: [
        SpinnerOverlayComponent
    ]
})
export class AppModule
{
}