import { Injectable } from '@angular/core';
import { AnyDocument } from '../model/any-document.model';

@Injectable({
    providedIn: 'root'
})
export class ShareService {
    permittedFileExtensions: any[] = [
        {type: 'audio', extension: 'flac', fileType: 'audio/flac'},
        {type: 'audio', extension: 'm4a', fileType: 'audio/x-m4a'},
        {type: 'audio', extension: 'mp3', fileType: 'audio/mp3'},
        {type: 'audio', extension: 'oga', fileType: 'audio/ogg'},
        {type: 'audio', extension: 'ogg', fileType: 'audio/ogg'},
        {type: 'audio', extension: 'wav', fileType: 'audio/opus'},
        {type: 'audio', extension: 'weba', fileType: 'audio/webm'},
        {type: 'audio', extension: 'flac', fileType: 'audio/flac'},
        {type: 'image', extension: 'bmp', fileType: 'image/bmp'},
        {type: 'image', extension: 'gif', fileType: 'image/gif'},
        {type: 'image', extension: 'ico', fileType: 'image/x-icon'},
        {type: 'image', extension: 'jfif', fileType: 'image/jpeg'},
        {type: 'image', extension: 'jpeg', fileType: 'image/jpeg'},
        {type: 'image', extension: 'jpg', fileType: 'image/jpeg'},
        {type: 'image', extension: 'pjp', fileType: 'image/jpeg'},
        {type: 'image', extension: 'pjpeg', fileType: 'image/jpeg'},
        {type: 'image', extension: 'png', fileType: 'image/png'},
        {type: 'image', extension: 'svg', fileType: 'image/svg+xml'},
        {type: 'image', extension: 'svgz', fileType: 'image/svg+xml'},
        {type: 'image', extension: 'tif', fileType: 'image/tiff'},
        {type: 'image', extension: 'tiff', fileType: 'image/tiff'},
        {type: 'image', extension: 'webp', fileType: 'image/webp'},
        {type: 'image', extension: 'xbm', fileType: 'image/x-xbitmap'},
        {type: 'text', extension: 'css', fileType: 'text/css'},
        {type: 'text', extension: 'csv', fileType: 'text/csv'},
        {type: 'text', extension: 'ehtml', fileType: 'text/html'},
        {type: 'text', extension: 'htm', fileType: 'text/html'},
        {type: 'text', extension: 'html', fileType: 'text/html'},
        {type: 'text', extension: 'shtm', fileType: 'text/html'},
        {type: 'text', extension: 'shtml', fileType: 'text/html'},
        {type: 'text', extension: 'text', fileType: 'text/plain'},
        {type: 'text', extension: 'txt', fileType: 'text/plain'},
        {type: 'video', extension: 'm4v', fileType: 'video/mp4'},
        {type: 'video', extension: 'mp4', fileType: 'video/mp4'},
        {type: 'video', extension: 'mpeg', fileType: 'video/mpeg'},
        {type: 'video', extension: 'mpg', fileType: 'video/mpeg'},
        {type: 'video', extension: 'ogm', fileType: 'video/ogg'},
        {type: 'video', extension: 'ogv', fileType: 'video/ogg'},
        {type: 'video', extension: 'webm', fileType: 'video/webm'},
    ];
    /**
     * Constructor
     *
     */
    constructor()
    {}

    shareFile = async function(title: string, text: string, document: AnyDocument) {
        try {
            // console.log('oi rafael');
            // const response = await fetch(document.publicUrl);
            // const blob = await response.blob();

            // const fileType = this.getFileType(document);
            // const file = new File([blob], 'Integ_' + document.documentKey, {type: fileType});

            // let nav: any = window.navigator;
    
            // console.log('aqui n deu erro');
            window['ReactNativeWebView'].postMessage(JSON.stringify(document.publicUrl));
            // if (window['ReactNativeWebView']) {
            //     console.log('entrou ReactNativeWebView');
            //     window['ReactNativeWebView'].postMessage(JSON.stringify(document.publicUrl));
            // } else {
            //     await nav.share({
            //         // title: title,
            //         // text: text,
            //         // url: document.publicUrl,
            //         files: [file]
            //     });
            // }
        } catch (err) {
            console.log(err.name, err.message);
        }
    };

    shareUrl(nav: any, title: string, text: string, document: any): boolean {
        if (nav.share) {
            nav.share({
                title: title,
                text: text,
                url: document.publicUrl,
            }).then(() => {
                console.log('Successful share')
                return true;
            }).catch((error) => {
                console.log('Error sharing', error);
                return false;
            });
        } else {
            console.log("Web Share API is not available in your browser.");
            return false;
        }
    }


    hasFileShare(document: any): boolean {
        if(document && document.fileExtension) {
            let permittedFile = this.permittedFileExtensions.find(permittedFileExtension => permittedFileExtension.extension == document.fileExtension.replace('.', ''));

            if(permittedFile) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    getFileType(document: any): string {
        let permittedFile = this.permittedFileExtensions.find(permittedFileExtension => permittedFileExtension.extension == document.fileExtension.replace('.', ''));

        return permittedFile.fileType;
    }
}