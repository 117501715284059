import { NgModule } from '@angular/core';
import { MatButtonModule, MatButtonToggleModule, MatCheckboxModule, MatDialogModule, MatFormFieldModule, MatIconModule, MatInputModule, MatSnackBarModule, MatToolbarModule, MatTooltipModule } from '@angular/material';

import { FuseSharedModule } from '@fuse/shared.module';

import { TranslateModule } from '@ngx-translate/core';

import { ShareDialogComponent } from 'app/component/share-dialog/share-dialog.component';
import { EmailTypeService } from 'app/main/service/email-type.service';

@NgModule({
    declarations: [
        ShareDialogComponent
    ],
    imports: [
        MatButtonModule,
        MatButtonToggleModule,
        MatDialogModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatSnackBarModule,
        MatToolbarModule,
        MatTooltipModule,
        MatCheckboxModule,
        
        FuseSharedModule,

        TranslateModule
    ],
    providers   : [
        EmailTypeService
    ],
    entryComponents: [
        ShareDialogComponent
    ]
})
export class ShareDialogModule
{
}
