import { Development } from "app/main/configurations/development/development.model";
import { AnyDocument } from "../any-document.model";

export class IntegCustomer
{
    id: string;
    name: string;
    fantasyName: string;
    postalCode: string;
    street: string;
    number: string;
    complement: string;
    city: string;
    state: string;
    phone: string;
    email: string;
    identifier: string;
    developments: Development[];
    companies: Development[];
    logo: AnyDocument;
    expanded?: boolean;

    constructor(integCustomer?)
    {
        integCustomer = integCustomer || {};
        this.id = integCustomer.id || '';
        this.name = integCustomer.name || '';
        this.fantasyName = integCustomer.fantasyName || '';
        this.postalCode = integCustomer.postalCode || '';
        this.street = integCustomer.street || '';
        this.number = integCustomer.number || '';
        this.complement = integCustomer.complement || '';
        this.city = integCustomer.city || '';
        this.state = integCustomer.state || '';
        this.phone = integCustomer.phone || '';
        this.email = integCustomer.email || '';
        this.identifier = integCustomer.identifier || '';
        this.logo = integCustomer.logo || '';
        this.developments = integCustomer.developments || [];
        this.companies = integCustomer.companies || [];
        this.expanded = integCustomer.expanded || false;
    }
}