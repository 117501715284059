import { Component, Inject, OnInit, ViewEncapsulation, ElementRef, Input } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material';

import { FormBuilder, FormGroup } from '@angular/forms';
import { Lightbox, LightboxConfig } from 'ngx-lightbox';
import { TranslateService } from '@ngx-translate/core';

import { GALLERY_CONF } from "ngx-image-gallery";

import { UmeDialogComponent } from 'app/component/ume-dialog/ume-dialog.component';
import { PostService } from 'app/main/service/post.service';

import { Post } from 'app/main/model/post.model';
import { PostComment } from 'app/main/model/post-comment.model';
import { User } from 'app/main/configurations/user/user.model';

import { AuthenticationService } from 'app/auth/authentication.service';
import { T } from '@angular/cdk/keycodes';

@Component({
    selector     : 'comment-timeline',
    templateUrl  : './comment-timeline.component.html',
    styleUrls    : ['./comment-timeline.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class CommentTimelineComponent implements OnInit {
    inlineConf: GALLERY_CONF = {
        imageOffset: '0px',
        inline: true,
        showDeleteControl: false,
        showImageTitle: false,
    };

    horizontalPosition: MatSnackBarHorizontalPosition = 'center';
    verticalPosition: MatSnackBarVerticalPosition = 'top';

    post: Post;

    dialogTitle: string;
    avatarUrl: string;

    postCommentFormTitle: string;
    postCommentForm: FormGroup;
    hasPostCommentDeleteAction: boolean = false;
    currentUserId: any;
    confirmDialogRef: MatDialogRef<UmeDialogComponent>;

    postAlbum = [];

    constructor(
        public matDialogRef: MatDialogRef<CommentTimelineComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: any,
        private translate: TranslateService,
        private snackBar: MatSnackBar,
        public _matDialog: MatDialog,
        private _formBuilder: FormBuilder,
        private _postService: PostService,
        private _authService: AuthenticationService,
        private _lightbox: Lightbox,
        private _lightboxConfig: LightboxConfig
    )
    {
        // Set the defaults
        this.post = _data.post;
 
        this.dialogTitle = this.translate.instant('NEWS.COMMENT-TIMELINE');

        this.avatarUrl = 'assets/images/avatars/engineer.png';
        this.hasPostCommentDeleteAction = this._authService.hasAction('DELETE_POST_COMMENT');

        this.loadLightbox();
    }

    ngOnInit() {
        this.postCommentForm = this.createPostCommentForm();
        this._authService.currentAvatar.subscribe((avatar) => {
            if(avatar) {
                this.avatarUrl = 'data:image/jpg;base64,' + avatar.documentFile;
            } else {
                this.avatarUrl = 'assets/images/avatars/engineer.png';
            }
        });

        if(this._authService.currentUserValue) {
            this.currentUserId = this._authService.currentUserValue.id;
        }
    }

    resetForm(): void {
        this.postCommentForm.reset();
    }

    createPostCommentForm(): FormGroup {
        return this._formBuilder.group({
            message      : [],
            alertDate    : []
        });
    }

    addComment(post: Post) {
        let message = this.postCommentForm.controls['message'].value;

        let postComment: PostComment = new PostComment();
        postComment.message = message;
        postComment.post = this.post;
        postComment.creationDate = new Date();   

        let createdBy: User = new User();
        createdBy.id = this._authService.currentUserValue.id;
        createdBy.name = this._authService.currentUserValue.name;
        createdBy.avatar = this._authService.currentAvatarValue;

        postComment.createdBy = createdBy;        
 
        this._postService.createPostComment(postComment).then((response) => {
            if(response) {
                this._postService.findPost(this.post.id).then((response) => {
                    if(response) {
                        this.post = response;
                    } else {
                        console.log('Error occurred. Contact support.');
                    }
               });
            //    this.listPosts();
            } else {
                console.log('Error occurred. Contact support.');
            }
        }).catch((error) => {
            console.log(error);
        });

        this.resetForm();
    }

    loadLightbox(): void {
        console.log(this.post);
        for(let image of this.post.images) {
            let logoLightbox = {src: image.url};

            this.postAlbum.push(logoLightbox);
        }
    }

    openLightbox(): void {
        console.log(this.postAlbum);
        this._lightbox.open(this.postAlbum, this.post.selectedImage);
    }

    deletePostComment(postComment: PostComment, index) {
        this.confirmDialogRef = this._matDialog.open(UmeDialogComponent, {
            disableClose: false
        });

        this.confirmDialogRef.componentInstance.confirmMessage = 'Deseja apagar este comentário ?';

        this.confirmDialogRef.afterClosed().subscribe(result => {
            if ( result )
            {
                this._postService.deletePostComment(postComment).then((response) => {
                    if(response) {
                        this._postService.findPost(this.post.id).then((response) => {
                            if(response) {
                                this.post = response;
                                console.log(this.post);
                            } else {
                                console.log('Error occurred. Contact support.');
                            }
                        });
                    } else {
                        console.log('Error occurred. Contact support.');
                    }
                });
            }
            this.confirmDialogRef = null;
        });
    }

    canDeleteComment(comment: PostComment): boolean {
        if(this.hasPostCommentDeleteAction || comment.createdBy.id == this.currentUserId) {
            return true;
        } else {
            return false;
        }
    }

    // callback on gallery image changed
    galleryImageChanged(index, post: Post) {
        post.selectedImage = index+1;
    }

    getCommentAvatar(comment: PostComment) {
        if(comment.createdBy.avatar) {
            return "data:image/jpg;base64," + comment.createdBy.avatar.documentFile;
        } else {
            return "assets/images/avatars/profile.jpg";
        }
    }
}