import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';

import { AuthenticationService } from 'app/auth/authentication.service';

import { Birthday } from 'app/main/model/birthday.model';

@Component({
	selector: 'birthday-list',
	templateUrl: './birthday-list.component.html',
	styleUrls: ['./birthday-list.component.scss']
})
export class BirthdayListComponent {
	hasBirthdayViewNotificationAction: boolean = false;

	constructor(
		private router: Router,
		private _authService: AuthenticationService,
        private _fuseSidebarService: FuseSidebarService,
       
	) {
		this.hasBirthdayViewNotificationAction = this._authService.hasAction('VIEW_BIRTHDAY_NOTIFICATION');
	}

	@Input() birthdays: Birthday[];

	viewBirthday(birthday): void {
		if(this._authService.hasAction('VIEW_BIRTHDAY')) {
			this._fuseSidebarService.getSidebar('quickPanel').toggleOpen();
			this.router.navigate(['/configurations/user/evaluate/' + birthday.userId]);
		}
	}
  
}