import { Employee } from './employee.model';
import { Development } from 'app/main/configurations/development/development.model';

export class EmployeeDevelopment
{
    employee: Employee;
    development: Development;

    constructor(employeeDevelopment?) {
        employeeDevelopment = employeeDevelopment || {};
        this.employee = employeeDevelopment.employee || '';
        this.development = employeeDevelopment.development || '';
    }

}
