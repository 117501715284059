import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material';

import { Router } from '@angular/router';
import { interval, Subscription } from 'rxjs';

import { TranslateService } from '@ngx-translate/core';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';

import { AuthenticationService } from 'app/auth/authentication.service';

import { locale as english } from 'app/i18n/en';
import { locale as portuguese } from 'app/i18n/pt';

import { UserNotificationService } from 'app/main/service/user-notification-service';
import { UserNotification } from 'app/main/model/user-notification.model';

import { BirthdayService } from 'app/main/service/birthday.service';
import { Birthday } from 'app/main/model/birthday.model';

@Component({
    selector     : 'quick-panel',
    templateUrl  : './quick-panel.component.html',
    styleUrls    : ['./quick-panel.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class QuickPanelComponent implements OnInit {
    horizontalPosition: MatSnackBarHorizontalPosition = 'center';
    verticalPosition: MatSnackBarVerticalPosition = 'top';

    date: Date;
    events: any[];
    notes: any[];
    settings: any;
    configs: any[];

    notificationSubscription: Subscription;
    notifications: UserNotification[];
    selectedNotifications :  UserNotification[] = [];

    newNotificationsCount: number = 0;
    totalNotificationsCount: number = 0;
    currentPage: number = 0;
    pageTotal: number = 20;
    pageSize: number = 20;

    birthdaySubscription: Subscription;
    todayBirthdays: Birthday[];
    monthBirthdays: Birthday[];

    currentDate: Date = new Date();

    hasBirthdayViewNotificationAction: boolean = false;

    isQuickPanel: boolean = true;

    constructor(
        private _authService: AuthenticationService,
        private _notificationService: UserNotificationService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private translate: TranslateService,
        private router: Router,
        private _fuseSidebarService: FuseSidebarService,
        private _birthdayService: BirthdayService,
        private snackbar: MatSnackBar
    )
    {
        this._fuseTranslationLoaderService.loadTranslations(english, portuguese);
        // Set the defaults
        this.date = new Date();

        this.settings = {
            notify: true,
            cloud : false,
            retro : true
        };
    }

    ngOnInit(): void {
        this.loadNotifications();
        this.loadBirthdays();
    }

    loadNotifications(): void {
        // Subscribe to update notifications on changes
        this._notificationService.onNotificationsChanged.subscribe(response => {
            if(response) {
                this.totalNotificationsCount = response.totalNotificationsCount;
                this.newNotificationsCount = response.newNotificationsCount;

                this.notifications = response.notifications;
            }
        });
      
        this._notificationService.getNotifications(this.currentPage, this.isQuickPanel).then((response) => {
            if(response) {
                this.totalNotificationsCount = response.totalNotificationsCount;
                this.newNotificationsCount = response.newNotificationsCount;
                
                this.notifications = response.notifications;
            }
        });

        this.notificationSubscription = interval(300000).subscribe((val) => {
            this._notificationService
                .getNotifications(this.currentPage, this.isQuickPanel)
                .catch((error) => {
                    console.log('Error while listing notifications: ' + error);
                });
        });
    }

    loadBirthdays(): void {
        this.hasBirthdayViewNotificationAction = this._authService.hasAction('VIEW_BIRTHDAY_NOTIFICATION');

        if(this.hasBirthdayViewNotificationAction) {
            this._authService.currentUser.subscribe((user) => {
                this._birthdayService.onBirthdaysChanged.subscribe((response) => {
                    if(response) {
                        this.fillBirthdays(response);
                    }
                });
                
            });

            this.birthdaySubscription = interval(60000).subscribe((val) => {
                if(this.date.getDay() != new Date().getDay()) {
                    this.date = new Date();

                    this._birthdayService.list().then((response) => {
                        this.fillBirthdays(response);
                    });
                }
            });
        }
    }

    fillBirthdays(birthdayResponse): void {
        this.todayBirthdays = birthdayResponse.todayBirthdays;
        this.monthBirthdays = birthdayResponse.monthBirthdays;
    }

    viewNotification(notification: UserNotification): void {
        let integCustomer = this._authService.currentIntegCustomerValue;
        if(notification.notificationObject.integCustomer.id == integCustomer.id) {
            this.selectedNotifications.push(notification); 

            this._notificationService.markAsViewed(this.selectedNotifications, this.currentPage, this.isQuickPanel).then((response) => {
                this._fuseSidebarService.getSidebar('quickPanel').toggleOpen();
                this.router.navigate([notification.notificationObject.route]);
            });
        } else {
            const message = this.translate.instant('Altere de Empresa para visualizar esta notificação');
            this.snackbar.open(message, this.translate.instant('GENERAL.CLOSE'), {
                duration: 5000,
                horizontalPosition: this.horizontalPosition,
                verticalPosition: this.verticalPosition,
            });
        }
    }

    incrementPage() {
        this.currentPage++;
        this.updatePageTotal();

        this.loadNotifications();
    }

    decrementPage() {
        this.currentPage--;
        this.updatePageTotal();

        this.loadNotifications();
    }

    updatePageTotal(): void {
        this.pageTotal = (this.currentPage + 1) * this.pageSize;

        if(this.pageTotal > this.totalNotificationsCount) {
            this.pageTotal = this.totalNotificationsCount;
        }
    }
}
