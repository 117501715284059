import { Component, ElementRef, Input, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import { CookieService } from 'ngx-cookie-service';
import { Subject } from 'rxjs';
import { filter, take, takeUntil } from 'rxjs/operators';
import { Router, NavigationEnd } from '@angular/router';

import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { TranslateService } from '@ngx-translate/core';

import { locale as english } from 'app/i18n/en';
import { locale as portuguese } from 'app/i18n/pt';

import { FuseMatchMediaService } from '@fuse/services/match-media.service';

import { AuthenticationService } from 'app/auth/authentication.service';

@Component({
    selector   : 'ume-shortcuts',
    templateUrl: './shortcuts.component.html',
    styleUrls  : ['./shortcuts.component.scss']
})
export class UmeShortcutsComponent implements OnInit, OnDestroy
{
    shortcutItems: any[] = [];
    filteredNavigationItems: any[];
    searching: boolean;
    mobileShortcutsPanelActive: boolean;
    isHome: boolean = true;

    route: string = 'home';

    @ViewChild('searchInput')
    searchInputField;

    @ViewChild('shortcuts')
    shortcutsEl: ElementRef;

    private _unsubscribeAll: Subject<any>;

    constructor(
        private _authService: AuthenticationService,
        private _cookieService: CookieService,
        private _fuseMatchMediaService: FuseMatchMediaService,
        private _mediaObserver: MediaObserver,
        private _renderer: Renderer2,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private translate: TranslateService,
        private router: Router
    )
    {
        this._fuseTranslationLoaderService.loadTranslations(english, portuguese);

        this.shortcutItems = [];
        this.searching = false;
        this.mobileShortcutsPanelActive = false;

        router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd)
            )
            .subscribe((currentRoute: NavigationEnd) => {
                if(currentRoute.url == '/' || currentRoute.url == '/home') {
                    this.isHome = true;
                } else {
                    this.isHome = false;
                }

                this.route = currentRoute.url;
                if(currentRoute.url.indexOf('/', 1) >= 0) {
                    this.route = currentRoute.url.replace(currentRoute.url.substr(currentRoute.url.indexOf('/', 1), currentRoute.url.length), '');
                }

            });

        this._unsubscribeAll = new Subject();
    }

    ngOnInit(): void
    {

        let salesTitle = this.translate.instant('SALES.SALES');;
        let documentsTitle = this.translate.instant('MEDIA.DOCUMENTS');
        let mediaTitle = this.translate.instant('MEDIA.MEDIA');
        let mileageTitle = this.translate.instant('MILEAGE.MILEAGE');
        // User's shortcut items
        this._authService.currentShortcuts.subscribe((response) => {
            this.shortcutItems = response;
        });

        if(this.shortcutItems) {
            this.shortcutItems.splice(0, 0, {
                'titleTranslate': 'Home',
                'shortcutType' : 'item',
                'imageSmall' : 'home',
                'expandIcon' : true,
                'hideHome' : true,
                'url' : '/home',
                'routeStart' : '/home'
            });
        }

        // Subscribe to media changes
        this._fuseMatchMediaService.onMediaChange
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {
                if ( this._mediaObserver.isActive('gt-sm') )
                {
                    this.hideMobileShortcutsPanel();
                }
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------


    /**
     * On menu open
     */
    onMenuOpen(): void
    {
        setTimeout(() => {
            this.searchInputField.nativeElement.focus();
        });
    }

    shortcutClick(shortcut): void {
        this.hideMobileShortcutsPanel();
        this.router.navigate([shortcut.url]);
    }

    /**
     * Show mobile shortcuts
     */
    showMobileShortcutsPanel(): void
    {
        this.mobileShortcutsPanelActive = true;
        this._renderer.addClass(this.shortcutsEl.nativeElement, 'show-mobile-panel');
    }

    /**
     * Hide mobile shortcuts
     */
    hideMobileShortcutsPanel(): void
    {
        this.mobileShortcutsPanelActive = false;
        this._renderer.removeClass(this.shortcutsEl.nativeElement, 'show-mobile-panel');
    }
}
