import { FuseNavigation } from '@fuse/types';

//MENU LATERAL
export const navigation: FuseNavigation[] = [
    {
        id       : 'home',
        title    : 'Home',
        translate: 'NAV.HOME-GROUP',
        type     : 'group',
        children : [
        {
            id       : 'Home',
            title    : 'Home',
            translate: 'NAV.HOME.TITLE',
            type     : 'item',
            icon     : 'home',
            url      : '/home',
            badge    : {
                title    : '25',
                translate: 'NAV.HOME.BADGE',
                bg       : '#F44336',
                fg       : '#FFFFFF'
            }
        }]
    },
    {
        id       : 'configurations',
        title    : 'Configurations',
        translate: 'NAV.CONFIGURATIONS',
        type     : 'group',
        children : [
            {
                id       : 'users',
                title    : 'Users',
                translate: 'NAV.USER.TITLE',
                type     : 'item',
                icon     : 'verified_user',
                url      : '/configurations/users'
            },
            {
                id       : 'real-estates',
                title    : 'RealEstates',
                translate: 'NAV.REAL-ESTATE.TITLE',
                type     : 'item',
                icon     : 'account_balance',
                url      : '/configurations/real-estates'
            },
            {
                id       : 'roles',
                title    : 'Roles',
                translate: 'NAV.ROLE.TITLE',
                type     : 'item',
                icon     : 'account_box',
                url      : '/configurations/roles'
            },
            {
                id       : 'general',
                title    : 'General',
                translate: 'NAV.GENERAL.TITLE',
                type     : 'item',
                icon     : 'email',
                url      : '/general',
                badge    : {
                    title    : '25',
                    translate: 'NAV.GENERAL.BADGE',
                    bg       : '#F44336',
                    fg       : '#FFFFFF'
                }
            },
        ]
    }
];
