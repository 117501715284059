import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { HttpClient, HttpParams } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import { BehaviorSubject, Observable } from 'rxjs';

import { AuthenticationService } from 'app/auth/authentication.service';

import { environment } from 'app/environment/environment';

import { Module } from 'app/main/development/modules/module.model';
import { Tower } from 'app/main/model/development/tower.model';
import { Development } from 'app/main/configurations/development/development.model';

import { TowersService } from 'app/main/development/towers/towers.service';

@Injectable()
export class SalesService implements Resolve<any>
{
    apiUrl = environment.apiUrl + "/sales";

    modules: any[];
    onModulesChanged: BehaviorSubject<any>;
    onTowerChanged: BehaviorSubject<any>;

    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     * @param {AuthenticationService} _authService
     * @param {TowersService} _towersService
     */
    constructor(
        private _httpClient: HttpClient,
        private _authService: AuthenticationService,
        private _towersService : TowersService
    )
    {
        // Set the defaults
        this.onModulesChanged = new BehaviorSubject({});
        this.onTowerChanged = new BehaviorSubject({});
    }

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any
    {
        return new Promise((resolve, reject) => {

            Promise.all([
                this.getModules()
            ]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }

    /**
     * Get modules
     *
     * @returns {Promise<any>}
     */
    getModules(development?): Promise<any> {
        let selectedDevelopment = development ? development : this._authService.currentDevelopmentValue;
        let params = {development: selectedDevelopment.id};

        return new Promise((resolve, reject) => {
            this._httpClient.get(this.apiUrl + "/module/list", {params: params})
                .subscribe((response: any) => {
                    this.modules = response;
                    this.onModulesChanged.next(this.modules);
                    this._towersService.getTowers();
                    resolve(response);
                }, reject);
        });
    }

    findModule(id): Promise<Module> {
        return new Promise((resolve) => {
            this._httpClient.get(this.apiUrl + "/find/" + id)
                .subscribe((response) => {
                    resolve(response as Module);
                });
        });
    }

    createModule(module: Module, towers?: Tower[]): Promise<any> {
        module.development = this._authService.currentDevelopmentValue;

        if(towers && towers.length > 0) {
            module.towers = towers;
        }

        return new Promise((resolve, reject) => {
            this._httpClient.post(this.apiUrl + "/create", {...module})
                .subscribe((response: any) => {
                    this.getModules();
                    resolve(response);
                }, reject);
        });
    }

    /**
     * Update module
     *
     * @param module
     * @returns {Promise<any>}
     */
    updateModule(module, towers?: Tower[]): Promise<any> {
        if(towers && towers.length > 0) {
            module.towers = towers;
        }

        return new Promise((resolve, reject) => {
            this._httpClient.put(this.apiUrl + '/update', {...module})
                .subscribe(response => {
                    this.getModules();
                    resolve(response);
                });
        });
    }

    /**
     * Deletes an existing module
     *
     * @param module
     * @returns {Promise<any>}
     */
    deleteModule(module): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.put(this.apiUrl + "/delete", {...module})
                .subscribe((response: any) => {
                    this.getModules();
                    resolve(response);
                }, reject);
        });
    }
}
