import { User } from 'app/main/configurations/user/user.model';

export class AnyDocument {
    id: string;
    documentFile: string;
    documentFileName: string;
    fileExtension: string;
    documentType: string;
    documentKey: string;
    url: string;
    publicUrl: string;
    createdBy: User;
    creationDate: Date;
    deletedBy: User;
    deletionDate: Date;
    size: number; 
    localUrl: string | ArrayBuffer;
    subtitle: string;

    constructor(anyDocument?) {
        anyDocument = anyDocument || {};
        this.id = anyDocument.id || '';
        this.documentFile = anyDocument.documentFile || '';
        this.documentFileName = anyDocument.documentFileName || '';
        this.fileExtension = anyDocument.fileExtension || '';
        this.documentType = anyDocument.documentType || '';
        this.documentKey = anyDocument.documentKey || '';
        this.url = anyDocument.url || '';
        this.publicUrl = anyDocument.publicUrl || '';
        this.createdBy = anyDocument.createdBy || '';
        this.creationDate = anyDocument.creatioNDate || '';
        this.deletedBy = anyDocument.deletedBy || '';
        this.deletionDate = anyDocument.deletionDate || '';
        this.size = anyDocument.size || '';
        this.localUrl = '';
        this.subtitle = anyDocument.subtitle || '';
    }
}