import { NgModule } from '@angular/core';

import { RouterModule } from '@angular/router';
import { MatButtonModule, MatDividerModule, MatIconModule, MatMenuModule, MatToolbarModule, MatBadgeModule, MatFormFieldModule, MatInputModule, MatSelectModule } from '@angular/material';

import { AngularEditorModule } from '@kolkov/angular-editor';

import { TranslateModule } from '@ngx-translate/core';

import { FuseSearchBarModule } from '@fuse/components';
import { UmeShortcutsModule } from 'app/layout/components/shortcuts/shortcuts.module';
import { FuseSharedModule } from '@fuse/shared.module';

import { ToolbarComponent } from 'app/layout/components/toolbar/toolbar.component';

import { LeadFormDialogModule } from 'app/main/lead/lead-form-dialog/lead-form-dialog.module';
import { IntegAcceptanceTermDialogModule } from 'app/main/integ/term/integ-acceptance-term-dialog.module';

import { DevelopmentsService } from 'app/main/configurations/developments/developments.service';
import { IntegCustomerService } from 'app/main/service/integ/integ-customer.service';
import { UserIntegTermService } from 'app/main/service/integ/user-integ-term.service';

@NgModule({
    declarations: [
        ToolbarComponent
    ],
    imports     : [
        RouterModule,

        MatBadgeModule,
        MatButtonModule,
        MatDividerModule,
        MatIconModule,
        MatMenuModule,
        MatToolbarModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,

        FuseSharedModule,
        FuseSearchBarModule,

        IntegAcceptanceTermDialogModule,

        UmeShortcutsModule,
        AngularEditorModule,
        LeadFormDialogModule,

        TranslateModule
    ],
    exports     : [
        ToolbarComponent
    ],
    providers   : [
        DevelopmentsService,
        IntegCustomerService,
        UserIntegTermService
    ]
})
export class ToolbarModule
{
}