import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
 
@Component({
    selector: 'avatar',
    templateUrl: "./avatar.component.html",
    styleUrls: ['./avatar.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class AvatarComponent implements OnInit {
    @Input() name: string;
    @Input() width: number = 40;
    @Input() height: number = 40;
    @Input() fontSize: number = 17;
    @Input() borderColor: string = '#01579b';
    @Input() fontColor: string = '#01579b';
    @Input() randomColor: boolean = true;
    @Input() fillBackground: boolean = false;
    @Input() randomBackground: boolean = true;
    @Input() backgroundColor: string = '#01579b';

    nameInitials: string;
    randomColorColor: string;

    ngOnInit() {
        let names = this.name.split(" ");
        let initials = names[0].substring(0, 1).toUpperCase();

        if (names.length > 1) {
            initials += names[names.length - 1].substring(0, 1).toUpperCase();
        }

        this.nameInitials = initials;

        this.setRandomColor();
    }

    setRandomColor() {
        let letters = '0123456789ABCDEF';
        let color = '#';

        for (let i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }

        this.randomColorColor = color;
    }
}