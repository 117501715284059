import { Component, Inject, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatButtonToggle, MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material';
import { FormBuilder, FormGroup } from '@angular/forms';

import { fuseAnimations } from '@fuse/animations';
import { TranslateService } from '@ngx-translate/core';

import { MobileConfigService } from 'app/main/common/mobile-config-service';
import { ShareService } from 'app/main/common/share-service';

import { SalesService }    from 'app/main/sales/sales.service';
import { SalesTowerService }  from 'app/main/sales/development-catalog/sales-tower.service';
import { DevelopmentCatalogService } from './development-catalog.service';

import { ShareDialogComponent } from 'app/component/share-dialog/share-dialog.component';

@Component({
    selector   : 'development-catalog',
    templateUrl: './development-catalog.component.html',
    styleUrls    : ['./development-catalog.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations   : fuseAnimations
})
export class DevelopmentCatalogComponent implements OnInit {
    shareDialogRef: MatDialogRef<ShareDialogComponent>;

    horizontalPosition: MatSnackBarHorizontalPosition = 'center';
    verticalPosition: MatSnackBarVerticalPosition = 'top';

    development;
    modules: any[];

    isMobile: boolean = false;

    constructor(
        public dialogRef: MatDialogRef<DevelopmentCatalogComponent>,
        private _salesService: SalesService,
        private _towersService: SalesTowerService,
        private _catalogService: DevelopmentCatalogService,
        private _mobileService: MobileConfigService,
        private _shareService: ShareService,
        private _formBuilder: FormBuilder,
        @Inject(MAT_DIALOG_DATA) private _data: any,
        public _matDialog: MatDialog,
        private _snackBar: MatSnackBar,
        private translate: TranslateService
    ) {
        this.development = _data.development;
        this.isMobile = _mobileService.isMobile();
    }

    ngOnInit(): void {
        this.loadModules();
    }

    loadModules(): void {
        this._salesService.getModules(this.development).then((response) => {
            this.modules = response;

            for(let mod of this.modules) {
                for(let tower of mod.towers) {
                    tower.unities = [];
                    tower.allComplete = false;
                    tower.isExpanded = true;

                    this._towersService.getFloors(tower).then((response) => {
                        for(let floor of response) {
                            for(let unity of floor.unities) {
                                if(unity.unityStatus.unityStatus == 'AVAILABLE') {
                                    unity.completed = false;
                                    tower.unities.push(unity);
                                }
                            }
                        }
                    });
                }
            }
        });
    }

    toggleTower(moduleIndex, towerIndex): void {
        this.modules[moduleIndex].towers[towerIndex].isExpanded = !this.modules[moduleIndex].towers[towerIndex].isExpanded;
    }

    updateAllComplete(moduleIndex: number, towerIndex: number) {
        this.modules[moduleIndex].towers[towerIndex].allComplete = this.modules[moduleIndex].towers[towerIndex].unities != null && this.modules[moduleIndex].towers[towerIndex].unities.every(u => u.completed);
    }

    someTower(moduleIndex: number, towerIndex: number): boolean {
        if (this.modules[moduleIndex].towers[towerIndex].unities == null) {
            return false;
        }

        return this.modules[moduleIndex].towers[towerIndex].unities.filter(u => u.completed).length > 0 && !this.modules[moduleIndex].towers[towerIndex].allComplete;
    }

    setAllTower(moduleIndex: number, towerIndex: number, completed: boolean) {
        this.modules[moduleIndex].towers[towerIndex].allComplete = completed;

        if (this.modules[moduleIndex].towers[towerIndex].unities == null) {
            return;
        }

        this.modules[moduleIndex].towers[towerIndex].unities.forEach(u => u.completed = completed);
    }

    updateUnity(moduleIndex, towerIndex, unityIndex): void {
        this.modules[moduleIndex].towers[towerIndex].unities[unityIndex].completed = !this.modules[moduleIndex].towers[towerIndex].unities[unityIndex].completed;

        this.updateAllComplete(moduleIndex, towerIndex);
    }

    generateCatalog(): void {
        let towers: any[] = [];
        let hasSelectedUnities: boolean = false;

        for(let mod of this.modules) {
            for(let tower of mod.towers) {
                let clonedTower = Object.assign({}, tower);

                let unities: any[] = [];

                for(let unity of tower.unities) {
                    if(unity.completed) {
                        hasSelectedUnities = true;
                        unities.push(unity);
                    }
                }

                clonedTower.unities = unities;

                towers.push(clonedTower);
            }
        }

        if(hasSelectedUnities) {
            this._catalogService.generate(this.development, towers).then((response) => {
                if(response.document) {

                    if(this.isMobile) {
                        let nav: any = window.navigator;
                        let title = 'Tabela';
                        let text = this.translate.instant('SHARE.DEVELOPMENT-CATALOG') + ' ' + this.development.name;

                        if(nav.share) {
                            this._shareService.shareFile(title, text, response.document);
                        }
                    } else {
                        this.openShareComponent(response.document);
                    }
                }
            });
        } else {
            let message = this.translate.instant('DEVELOPMENT.NO-UNITIES-TO-CATALOG');
            this._snackBar.open(message, this.translate.instant('GENERAL.CLOSE'), {
                duration: 3000,
                horizontalPosition: this.horizontalPosition,
                verticalPosition: this.verticalPosition
            });
        }
    }

    openShareComponent(document: any): void {
        this.shareDialogRef = this._matDialog.open(ShareDialogComponent, {
            panelClass: 'share-dialog',
            position : {
                'top' : '100px'
            },
            data: {
                document: document
            },
            disableClose: false
        });

        this.shareDialogRef.componentInstance.shareMessage = this.translate.instant('SHARE.DEVELOPMENT-CATALOG') + ' ' + this.development.name + " " + encodeURI(document.publicUrl);
        this.shareDialogRef.componentInstance.document = document;

        this.shareDialogRef.afterClosed().subscribe(result => {
            if ( result ) {
                
            }
            this.shareDialogRef = null;
        });
    }

    cancel(): void {
        this.dialogRef.close(false);
    }
}
