import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';

import { environment } from 'app/environment/environment';

import { AuthenticationService } from 'app/auth/authentication.service';

@Injectable()
export class DevelopmentCatalogService
{
    apiUrl = environment.apiUrl + "/development";

    routeParams: any;

    constructor(
        private _httpClient: HttpClient,
        private _authService: AuthenticationService
    )
    {
    }

    generate(development, towers): Promise<any> {
        let request = {
            development: development,
            towers: towers
        };

        return new Promise((resolve, reject) => {
            this._httpClient.post(this.apiUrl + "/catalog", request).subscribe((response: any) => {
                resolve(response);
            }, reject);
        });
    }

}