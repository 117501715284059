import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { Router } from '@angular/router';

import { UnitiesService } from 'app/main/user/unity/unities.service';

@Component({
    selector: "commissions-card",
    templateUrl: "./commissions-card.component.html",
    styleUrls: ["./commissions-card.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class CommissionsCardComponent implements OnInit {
    negotiatingUnities: any[] = [];
    soldUnities: any[] = [];

    constructor(
        private _unitiesService: UnitiesService,
        private router: Router
    ) {}

    ngOnInit() {
        this._unitiesService.getUnities().then((response) => {
            this.negotiatingUnities = response.filter(item => {
                return item.unity.unityStatus.unityStatus != 'SOLD';
            });
            
            this.soldUnities = response.filter(item => {
                return item.unity.unityStatus.unityStatus == 'SOLD';
            });
        });
    }

    selectUnity(unityHistory): void {
        this.router.navigate(['/sales/unity/'+unityHistory.unity.id]);
    }

    viewMyUnities(): void {
        this.router.navigate(['/user/unity/list']);
    }

    viewSales(): void {
        this.router.navigate(['/sales/home']);
    }
}