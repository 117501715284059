import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { AuthenticationService } from 'app/auth/authentication.service';

@Injectable()
export class AuthGuardService implements CanActivate {

	constructor (
  		private _authService: AuthenticationService,
  		private router: Router
  	) {}

  	canActivate(): boolean {
    	if (!this._authService.currentUserValue && !this._authService.isApp()) {
    		this.router.navigate(['/auth/login']);
    		return false;
		} else if(!this._authService.currentUserValue) {
			this.router.navigate(['/auth/login/app']);
    		return false;
    	} else if(!this._authService.currentDevelopmentValue) {
    		this.router.navigate(['/auth/select-development']);
      		return false;
    	}

    	return true;
  	}
}