import { Injectable, NgModule } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { map, catchError, finalize, timeout } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpHeaders, HttpRequest, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition, } from '@angular/material';

import { TranslateService } from '@ngx-translate/core';

import { AuthenticationService } from 'app/auth/authentication.service';

@Injectable()
export class HTTPStatus {
    private requestInFlight$: BehaviorSubject<boolean>;
    constructor() {
        this.requestInFlight$ = new BehaviorSubject(false);
    }

    setHttpStatus(inFlight: boolean) {
        this.requestInFlight$.next(inFlight);
    }

    getHttpStatus(): Observable<boolean> {
        return this.requestInFlight$.asObservable();
    }
}

@Injectable()
export class HttpsRequestInterceptor implements HttpInterceptor {
    horizontalPosition: MatSnackBarHorizontalPosition = 'center';
    verticalPosition: MatSnackBarVerticalPosition = 'top';

	constructor(
        private snackBar: MatSnackBar,
        private translate: TranslateService,
        private status: HTTPStatus,
        private _authService: AuthenticationService,
        private route: ActivatedRoute,
        private router: Router
    ) {}

	intercept(
		req: HttpRequest<any>,
		next: HttpHandler,
	): Observable<HttpEvent<any>> {
        this.status.setHttpStatus(true);

        let _headers: HttpHeaders;

        if(this._authService.currentUserValue) {
            _headers = new HttpHeaders({
                'Content-Type' :    'application/json',
                'Authorization' : 'Bearer ' + this._authService.currentUserValue.token,
                'Ume-User' : this._authService.currentUserValue.id.toString(),
                'Is-App' : this._authService.currentUserValue.isApp.toString()
            });
        } else {
            _headers = new HttpHeaders({
                'Content-Type':    'application/json'
            });
        }

        if(this._authService.currentIntegCustomerValue) {
            _headers = _headers.append('Integ-Customer', this._authService.currentIntegCustomerValue.id);
        }
        
        //Add some headers here
        let dupReq = req.clone({
            headers: _headers
        });
       
        //Transforms empty string values into null so it passes the Spring @Size validation
        if(dupReq.body) {
            let newBody = JSON.parse(JSON.stringify(dupReq.body), function(key, value) {
                if (value == '')
                    return null;
                return value;
            });
            dupReq = dupReq.clone({ body: newBody });
        }
        
		return next.handle(dupReq).pipe(
            timeout(60000),
     		map(event => {
                return event;
            }),

            catchError((err) => {
                return this.error(err);
     		}),
            finalize(() => {
                this.status.setHttpStatus(false);
            })
     	)
    }
    
    error(err) {
        let errorMessage = '';
        let requestUrl = err.url;

        if (err.error instanceof ErrorEvent) {
            // client-side error
            errorMessage = `Error: ${err.error.message}`;
        } else {
            // server-side error
            errorMessage = `Error Code: ${err.status}\nMessage: ${err.message}`;
        }

        if(err.name && err.name == 'TimeoutError') {
            let message = this.translate.instant('ERROR.TIMEOUT');
            
            this.snackBar.open(message, this.translate.instant('GENERAL.CLOSE'), {
                duration: 5000,
                horizontalPosition: this.horizontalPosition,
                verticalPosition: this.verticalPosition,
            });
        } else if(err.status == 401) {
            this._authService.logout();
   
            let message;
            if(err.status == 401) {
                message = this.translate.instant('GENERAL.ACCESS-DENIED');
            } else if(err.status == 0) {
                message = this.translate.instant('ERROR.SERVICE_NOT_FOUND');
            }

            this.snackBar.open(message, this.translate.instant('GENERAL.CLOSE'), {
                duration: 5000,
                horizontalPosition: this.horizontalPosition,
                verticalPosition: this.verticalPosition,
            });
     
            let route = this._authService.getLogoutRoute();

            this.router.navigate([route]);
        } else if( err.status == 400 && (requestUrl.includes("/user/login") || requestUrl.includes("/user/password") || requestUrl.includes("/user/profile"))) {
            let message = this.translate.instant('GENERAL.BAD-CREDENTIALS');
            this.snackBar.open(message, this.translate.instant('GENERAL.CLOSE'), {
                duration: 5000,
                horizontalPosition: this.horizontalPosition,
                verticalPosition: this.verticalPosition,  
            });
        } else {
            let message = '';

            if(err.status != 404) {
                if(err.error && err.error.Error) {
                    for (var i = 0; i <= err.error.Error.length - 1; i++) {
                        message = message + this.translate.instant('GENERAL.'+err.error.Error[i].source.toUpperCase())
                            + ': ' + this.translate.instant('ERROR.'+err.error.Error[i].reasonCode.toUpperCase());
                        if (i != err.error.Error.length - 1) {
                            message = message + ', ';
                        }
                    }
                } else if(err.error && err.error.errorCode == "0000") {
                    message = this.translate.instant('GENERAL.' + err.error.reasonCode);
                } else {
             
                }
            } else {
                message = this.translate.instant('ERROR.SERVICE_NOT_FOUND');
            }

            this.snackBar.open(message, this.translate.instant('GENERAL.CLOSE'), {
                duration: 5000,
                horizontalPosition: this.horizontalPosition,
                verticalPosition: this.verticalPosition,
            });
        }
    
        return throwError(errorMessage);
    }
}
        
        
@NgModule({
providers: [
	{
		provide: HTTP_INTERCEPTORS,
		useClass: HttpsRequestInterceptor,
		multi: true,
	},
],
})

export class Interceptor {}