import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

import { AuthenticationService } from 'app/auth/authentication.service';

import { environment } from 'app/environment/environment';

@Injectable()
export class UnitiesService implements Resolve<any>
{
    apiUrl = environment.apiUrl + "/user/unity";
    routeParams: any;

    unities: any[];

    onUnitiesChanged: BehaviorSubject<any>;
    onSearchTextChanged: Subject<any>;
    onFilterChanged: Subject<any>;

    searchText: string;
    filterBy: string;

    constructor(
        private _httpClient: HttpClient,
        private _authService: AuthenticationService
    )
    {
        this.onUnitiesChanged = new BehaviorSubject({});
        this.onSearchTextChanged = new Subject();
        this.onFilterChanged = new Subject();
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {

        return new Promise((resolve, reject) => {

            Promise.all([
                this.getUnities()
            ]).then(
                () => {
                    this.onSearchTextChanged.subscribe(searchText => {
                        this.searchText = searchText;
                        this.getUnities();
                    });

                    this.onFilterChanged.subscribe(filter => {
                        this.filterBy = filter;
                        this.getUnities();
                    });

                    resolve();
                },
                reject
            );
        });
    }

    getUnities(): Promise<any> {
        let currentUser = this._authService.currentUserValue;
        let company = false;

        let request = {
              user : currentUser,
              company: company          
        };
       
        return new Promise((resolve, reject) => {
            this._httpClient.post(this.apiUrl + "/list", request).subscribe((response: any) => {
                this.unities = response;
                this.onUnitiesChanged.next(this.unities);
                resolve(response);
            }, reject);
        });
    }
}