import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';

import { environment } from 'app/environment/environment';
import { AuthenticationService } from 'app/auth/authentication.service';

@Injectable()
export class UserService
{
    apiUrl = environment.apiUrl + "/user";

    routeParams: any;
    user: any;
    onUserChanged: BehaviorSubject<any>;

    constructor(
        private _httpClient: HttpClient,
        private _authService: AuthenticationService
    )
    {
        // Set the defaults
        this.onUserChanged = new BehaviorSubject({});
    }

    findById(userId?): Promise<any> {
        let id = userId || this.routeParams.id;

        return new Promise((resolve, reject) => {
            if (this.routeParams && this.routeParams.id === 'new' ) {
                this.onUserChanged.next(false);
                resolve(false);
            }
            else {
                this._httpClient.get(this.apiUrl + "/find/" + id).subscribe((response: any) => {
                    this.user = response;
                    this.onUserChanged.next(this.user);
                    resolve(response);
                }, reject);
            }
        });
    }

    updateUser(user): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.put(this.apiUrl + "/update", user)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    createUser(user): Promise<any>
    {
        let createdBy = this._authService.currentUserValue;
        user.createdBy = createdBy;

        return new Promise((resolve, reject) => {
            this._httpClient.post(this.apiUrl + "/create", user)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    evaluateUser(user, approved, observation): Promise<any> {
        let currentUser = this._authService.currentUserValue;

        let request = {
            user: user,
            evaluatedBy: currentUser,
            approved: approved,
            observation: observation
        }

        return new Promise((resolve, reject) => {
            this._httpClient.put(this.apiUrl + "/evaluate", request)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    listKinships(): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get(this.apiUrl.replace("user", "kinship") + "/list")
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }
}