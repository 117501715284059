import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
    selector   : 'integ-acceptance-term-dialog',
    templateUrl: './integ-acceptance-term-dialog.component.html',
    styleUrls    : ['./integ-acceptance-term-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class IntegAcceptanceTermDialogComponent
{
    integTermUrl: any;
    actionButtons: boolean = true;
    
    constructor(
        public dialogRef: MatDialogRef<IntegAcceptanceTermDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: any,
        private _sanitizer: DomSanitizer
    )
    {
        this.integTermUrl = this._sanitizer.bypassSecurityTrustResourceUrl(_data.integTermUrl);
        this.actionButtons = _data.actionButtons;
    }

    getIntegTermUrl(): SafeResourceUrl {
        if(this.integTermUrl && this.integTermUrl.length > 0) {
            return this._sanitizer.bypassSecurityTrustResourceUrl(this.integTermUrl);
        }

        return "";
    }
}