import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';

import { environment } from 'app/environment/environment';
import { AuthenticationService } from 'app/auth/authentication.service';

import { UserMileage } from 'app/main/model/user-mileage.model';
import { MileageRegistry } from 'app/main/model/mileage-registry.model';

import { MileageRaceStanding } from 'app/main/model/mileage-race-standing.model'
import { MileageStatementType } from '../model/mileage-statement-type.model';

@Injectable()
export class MileageService implements Resolve<any>
{
    apiUrl = environment.apiUrl + "/mileage";

    routeParams: any;
    userMileage: UserMileage;
    mileageRegistry: MileageRegistry;
    mileageRegistries: MileageRegistry[];
    onMileageRegistryChanged: BehaviorSubject<any>;
    onMileageRegistriesChanged: BehaviorSubject<any>;
    onUserMileageChanged: BehaviorSubject<any>;
    onMileageRaceStandingChanged: BehaviorSubject<any>;
    mileageRaceStandings: MileageRaceStanding[];

    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     * @param {AuthenticationService} _authService
     */
    constructor(
        private _httpClient: HttpClient,
        private _authService: AuthenticationService
    )
    {
        // Set the defaults
        this.onUserMileageChanged = new BehaviorSubject({});
        this.onMileageRegistryChanged = new BehaviorSubject({});
        this.onMileageRegistriesChanged = new BehaviorSubject({});
        this.onMileageRaceStandingChanged = new BehaviorSubject({});
    }

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any
    {
        this.routeParams = route.params;

        return new Promise((resolve, reject) => {

            Promise.all([
                this.findMileageRegistry()
            ]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }

    /**
     * Get mileageRegistry
     *
     * @returns {Promise<any>}
     */
    listMileageRegistries(mileageRegistryStatus?, equal?): Promise<any> {
        let request = {
            mileageRegistryStatus: mileageRegistryStatus,
            equal: equal
        };

        return new Promise((resolve, reject) => {
            this._httpClient.post(this.apiUrl + "/registry/list", {...request})
                .subscribe((response: any) => {
                    this.mileageRegistries = response;
                    this.onMileageRegistriesChanged.next(this.mileageRegistries);
                    resolve(response);
                }, reject);
        });
    }

    listTopUserBalances(): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get(this.apiUrl + "/user/top")
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    listTopRescuedProducts(): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get(this.apiUrl + "/product/top")
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    /**
     * Get mileageRegistry
     *
     * @returns {Promise<any>}
     */
    findMileageRegistry(registryId?): Promise<any> {
        let id = registryId || this.routeParams.id;

        return new Promise((resolve, reject) => {
            this._httpClient.get(this.apiUrl + "/registry/find/" + id)
                .subscribe((response: any) => {
                    this.mileageRegistry = response;
                    this.onMileageRegistryChanged.next(this.mileageRegistry);
                    resolve(response);
                }, reject);
        });
    }

    /**
     * Changes mileage registry status
     *
     * @returns {Promise<any>}
     */
    changeMileageRegistryStatus(mileageRegistry: MileageRegistry, status): Promise<any> {
        let user = this._authService.currentUserValue;

        let request = {
            mileageRegistry: mileageRegistry,
            mileageRegistryStatus: status,
            user: user
        };

        return new Promise((resolve, reject) => {
            this._httpClient.put(this.apiUrl + "/registry/update", {...request})
                .subscribe((response: any) => {
                    if(response) {
                        this.findMileageRegistry(mileageRegistry.id);
                        resolve(response);
                    }
                });
        });
    }

    /**
     * Get userMileage
     *
     * @returns {Promise<any>}
     */
    findUserMileage(): Promise<any> {
        let user = this._authService.currentUserValue;
        let development = this._authService.currentDevelopmentValue;

        return new Promise((resolve, reject) => {
            this._httpClient.get(this.apiUrl + "/user/balance/" + development.id + "/" + user.id)
                .subscribe((response: any) => {
                    this.userMileage = response;
                    this.onUserMileageChanged.next(this.userMileage);
                    resolve(response);
                }, reject);
        });
    }

    findUserHomeMileage(): Observable<any> {
        let user = this._authService.currentUserValue;
        let development = this._authService.currentDevelopmentValue;

        return this._httpClient.get(`${this.apiUrl}/user/home/${development.id}/${user.id}`);
    }

    findUserMileageObs(): Observable<any> {
        let user = this._authService.currentUserValue;
        let development = this._authService.currentDevelopmentValue;
        const url = `${this.apiUrl}/user/balance/${development.id}/${user.id}`;
        return this._httpClient.get<any>(url);
    }

    /**
     * Rescue Products
     *
     * @returns {Promise<any>}
     */
    rescue(userMileage, cart): Promise<any> {
        let user = this._authService.currentUserValue;
        let _userMileage = {
            id: userMileage.id
        };

        let request = {
            userMileage: _userMileage,
            mileageProducts: cart,
            user: user
        };

        return new Promise((resolve, reject) => {
            this._httpClient.post(this.apiUrl + "/registry/rescue", {...request})
                .subscribe((response: any) => {
                    if(response) {
                        this.findUserMileage();
                        resolve(response);
                    }
                });
        });
    }


    /**
     * List registry types
     *
     * @returns {Promise<any>}
     */
    listRegistryTypes(): Promise<any> {

        return new Promise((resolve, reject) => {
            this._httpClient.get(this.apiUrl + "/registry/type/list")
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    listStatementTypes(): Observable<MileageStatementType[]> {
        const url = `${this.apiUrl}/registry/type/list`
        return this._httpClient.get<MileageStatementType[]>(url);
    }

    /**
     * List registry types
     *
     * @returns {Promise<any>}
     */
    listMileageRaceRanking(race): Promise<any> {
        let request = { 
            race: race
        }

        return new Promise((resolve, reject) => {
            this._httpClient.post(this.apiUrl + "/race/ranking", {...request})
                .subscribe((response: any) => {
                    this.mileageRaceStandings = response;
                    this.onMileageRaceStandingChanged.next(this.mileageRaceStandings);
                    resolve(response);
                }, reject);
        });
    }
        /**
     * Changes mileage registry status
     *
     * @returns {Promise<any>}
     */
    cancelMileageRescue(mileageRegistry: MileageRegistry, status): Promise<any> {
         let user = this._authService.currentUserValue;

        let request = {
            mileageRegistry: mileageRegistry,
            mileageRegistryStatus: status,
            user: user
        };

        return new Promise((resolve, reject) => {
            this._httpClient.post(this.apiUrl + "/registry/rescue/cancel", {...request})
                .subscribe((response: any) => {
                    if(response) {
                       //this.findMileageRegistry(mileageRegistry.id);
                        resolve(response);
                    }
                });
        });
    }
}
